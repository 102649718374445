/*
  DataSetsVisualised.module.scss

  This file contains all scoped style for DataSetsVisualised.jsx.
*/

@import '../../Styles/global';

.messageBox {
  margin-top: 24px;

  .entitiesNotRequiredBold {
    font-weight: 700;
  }
}

.failedMessage {
  text-align: center;
  margin-top: 40px;
}

.filterContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
  border-bottom: 2px solid $GREEN_02;

  .filterTitle {
    font-weight: $SEMI_BOLD;

    &.isRequired {
      &:after {
        content: ' *';
        display: inline;
        color: $ERROR;
      }
    }
  }

  .titleArea {
    font-size: font-size(-1);
  }

  .reportingYearFilterContainer {
    padding: 24px 24px 0 24px;

    &.bottomMargin {
      margin-bottom: 32px !important;
    }

    @include mobile {
      padding: 24px 12px;
    }

    .reportingYearDropdown {
      max-width: 632px;

      .clearButton {
        margin-top: 10px;
      }
    }
  }

  .listSelectSelectAll {
    background-color: $WHITE;
    padding: 0 16px;

    .selectAllCheckbox {
      padding: 16px 0;
      display: flex;
      border-bottom: 2px solid $PRIMARY;
      font-size: font-size(0);
      font-weight: $REGULAR;
    }
  }

  .entityFilterContainer {
    margin-top: 32px;
    padding: 32px 24px 24px 24px;
    border-top: 1px solid $PRIMARY;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    column-gap: 24px;

    @include tablet {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    @include mobile {
      padding: 0;
    }

    .bodyTypePortfolioContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 24px;
      background-color: $GREEN_01;
      height: fit-content;
      border-radius: 8px;

      @include mobile {
        padding: 12px;
        border-radius: 0;
      }
    }

    .entityOptionsContainer {
      @include mobile {
        padding: 12px 12px 24px 12px;
      }

      .fullWidth {
        max-width: 100%;
        margin-bottom: 0 !important;

        & > div {
          & > div {
            padding: 0;
          }
        }
      }

      .groupedListStyle {
        @include mobile {
          max-height: 356px;
          border-radius: 4px;
          border: 2px solid $GREEN_02;
          box-shadow: 0px 4px 18px -12px rgba(0, 0, 0, 0.2) inset;
          max-height: 356px;
        }
      }
    }
  }

  .entitySelectionControlButtons {
    padding: 0 24px 0 24px;
    display: flex;
    justify-content: space-between;

    .disabledAbolishButton {
      background-color: $GREY_02 !important;
      cursor: not-allowed !important;
      text-decoration: line-through !important;
      color: $GREY_04 !important;
      border-color: transparent !important;

      &:hover,
      &:focus {
        background-color: $GREY_02 !important;
        cursor: not-allowed !important;
        text-decoration: line-through !important;
        color: $GREY_04 !important;
        border-color: transparent !important;
      }
    }
  }

  .applyFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
    }

    p {
      margin: 0;
      vertical-align: middle;
    }

    .groupedButtons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      @include mobile {
        align-self: flex-end;
      }

      .filterButtons {
        display: flex;
        gap: 8px;
      }
    }
  }

  .exportAllCSV {
    width: fit-content;
  }

  .dataSetContainer {
    margin-top: 32px;
    padding: 32px 24px 24px 24px;
    border-top: 1px solid $PRIMARY;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 24px;

    @include tablet {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    @include mobile {
      padding: 0;
    }

    .dataSetContent {
      width: 100%;
      display: grid;

      .nonFinancialContent {
        margin-top: 10px;
        max-height: 352px;
        overflow-y: auto;

        @include mobile {
          border: 2px solid #a9c6c9;
          border-radius: 4px;
        }
      }

      .outerFinancialContent {
        @include mobile {
          padding-left: 12px;
          padding-right: 12px;
          padding-bottom: 48px;
        }
      }

      .title {
        font-size: font-size(-1);
        @include mobile {
          padding: 24px 12px 0px 12px;
        }

        &.required {
          &:after {
            content: '*';
            display: inline;
            color: $ERROR;
          }
        }
      }

      .dataSetBody {
        font-size: font-size(-1);
        @include mobile {
          padding: 0px 12px 12px 12px;
        }
      }
    }
  }
}

.additionalFilterWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.loadingDataGraphFilters {
  margin-top: 20px;
  min-height: 100px;
}

.loadingDataGraphs {
  margin-top: 10px;
  min-height: 400px;
}
