/*
  Pagination.module.scss

  This file contains all scoped style for Pagination.jsx.
*/

@import '../../Styles/global';

#pagination {
  display: flex;
  justify-content: space-between;

  a,
  button {
    font-size: 0;
    min-width: 44px;
    min-height: 44px;

    &:disabled {
      text-decoration: none;
      align-items: center;

      svg {
        color: $GREY_04;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    &.prev {
      svg {
        transform: rotate(-90deg);
      }
    }

    &.next {
      svg {
        transform: rotate(90deg);
      }
    }

    &.first,
    &.last,
    &.target {
      font-size: font-size(0);
      display: inline-flex;
      gap: 8px;
    }

    &.first {
      flex-direction: row-reverse;

      svg {
        transform: scale(-1);
      }
    }

    &.target {
      color: $BLACK !important;
      justify-content: center;
      align-items: center;
    }

    &:focus {
      position: relative;
      z-index: 1;
    }
  }

  .jumpers {
    display: flex;
    gap: 16px;
  }

  menu {
    display: flex;
    gap: 6px;

    @include mobile {
      gap: 6px;
    }

    li {
      list-style: none;
    }
  }
}

.noResults {
  margin: 0 auto;
  text-align: center;
}
