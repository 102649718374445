/*
  PageNavigation.module.scss

  This file contains all scoped style for PageNavigation.jsx.
*/

@import '../../Styles/global';

#pageNavigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  .navCard {
    padding: 10px;
    border-radius: 2px;
    display: grid;
    grid-template-rows: 24px;
    font-size: font-size(0);
    word-break: break-word;

    .navlink {
      text-decoration: underline;
    }

    .title {
      font-weight: $REGULAR;
      text-decoration: none;
    }

    svg {
      justify-self: center;
      height: 24px;
      width: 24px;
      color: $PRIMARY;
    }

    &.prev {
      grid-template-columns: 1fr 5fr;
      text-align: left;

      &:before {
        left: 0;
      }

      svg {
        transform: rotate(180deg);
        margin-right: 10px;
      }

      span {
        grid-column: 2;
      }
    }

    &.next {
      grid-template-columns: 5fr 1fr;
      text-align: right;
      grid-column: 2;

      @include mobile {
        grid-column: 1;
      }

      &:before {
        right: 0;
        left: unset;
      }

      svg {
        margin-left: 10px;
      }
    }

    &.disabled {
      pointer-events: none;

      svg {
        color: $GREY_03;
      }

      &:before {
        background-color: $GREY_02;
      }
    }

    a {
      display: block;
      z-index: 1;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: ' ';
      }
    }

    .invisibleTitle {
      opacity: 0.001;
    }
  }

  @include mobile {
    grid-template-columns: 1fr;
  }
}
