/*
  FilterGroupDropdown.module.scss

  This file contains all scoped style for FilterGroupDropdown.jsx.
*/

@import '../../Styles/global';

.filterDropdownWrapper {
  max-width: 640px;
  height: 100%;

  .dropdownHeader {
    border: none;
    border-radius: 2px;
    display: flex;
    align-items: center;
    height: 100%;

    button {
      span {
        font-size: 0;
      }

      height: 100%;
      width: 44px;
      min-height: 44px;
    }

    // drop down accordion triangle
    .triangle {
      display: flex;

      &.buttonDropdown {
        span {
          font-size: font-size(0);
          margin-right: 8px;
        }

        width: 100%;

        &:before {
          border-top: 5.77px solid $BLACK;
        }

        &:hover {
          background-color: $GREEN_02;
        }
      }

      svg {
        color: inherit;
        width: 24px;
        height: 24px;
      }

      &[aria-expanded='true'] {
        svg {
          transform: scale(-1);
        }
      }
    }
  }

  .dropdownOptions {
    position: absolute;
    border: 1px solid $GREY_04;
    border-radius: 4px;
    z-index: $TOOLTIP_Z_INDEX;
    width: fit-content;
    min-height: 44px;
    min-width: 400px;
    background-color: $WHITE;
    margin-top: 8px;
  }

  .floatRight {
    right: 0;
  }
}
