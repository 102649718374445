/*
  AnnualReportSearch.module.scss

  This file contains all scoped style for AnnualReportSearch.jsx.
*/

@import '../../Styles/global';

#AnnualReportSearch {
 background-color:grey;
}

@include print {
  #AnnualReportSearch {
    display: none;
  }
}

#annualReportSearchContainer{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #387B85;
  background: #FFF;
  margin-bottom:32px;

  #searchButton{
    display: flex;
    height: 56px;
    padding-left: var(--Value-Unit150, 24px);
    gap: 16px;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    background: #E1ECEB;
    align-items: center;
    justify-content: space-between;
    .title{
      color: var(--Black, #000);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      padding: 0;
      margin: 0;
    }
    .buttonArrowWrapper {
      display: flex;
      padding: 10px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      svg{
        width: 24px;
        height: 24px;
      }
    }

    .rotateArrow {
      transform: rotate(180deg);
    }

  }
  #searchBody{
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-direction: column;
    #searchComponentContainer{
      display: flex;
      flex-direction: column;
      width:100%;

      #textSearchContainer{
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        width:100%;

        #innerTextSearchContainer{
          display:flex;
          width:100%;
          border: 1px solid #868686;
          .textField{
            flex: 1 0 0;
            overflow: hidden;
            color: #000;
            text-overflow: ellipsis;
            white-space: nowrap;
            border:0;
            width:90%;
  
            /* Paragraph */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
          .crossButton{
            display: flex;
            padding: 10px;
            align-items: flex-start;
            background-color:#FFF;
            border-left: 1px solid #868686;
          }
        }

        .searchButton{
          display: flex;
          padding: 10px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 0px 2px 2px 0px;
          border: 0px solid #515151;
          background: #005562;
        }
      }
      #toolingContainer{
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        flex-direction: column;
        #checkButtonContainer{
          display: flex;
          align-self: stretch;
          flex-wrap: wrap;
          align-content: center;
          padding-top: 12px;
        }
        #textContainer{
          display: flex;
          align-items: center;
          align-content: center;
          align-self: stretch;
          flex-wrap: wrap;
          justify-content: flex-end;
        }
      }
    }
    #buttonContainer{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;

      .nextButton{
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background: #005562;
        text-decoration: none;
        color:black;
      }

      .endButton{
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 2px;
        border: 1px solid #679BA2;
        background: #E1ECEB;
        text-decoration: none;
        color:black;
      }

      .disabledButton{
        background: grey;
      }
    }
  }
}
