/*
  MainHeader.module.scss

  This file contains all scoped style for MainHeader.jsx.
*/

@import '../../Styles/global';

#header {
  width: 100vw;
  height: 80px;
  padding: 16px $HEADER_FOOTER_GUTTER;
  align-items: center;
  box-shadow: $SHADOW;
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(12px);
  z-index: $HEADER_Z_INDEX;

  @include tablet {
    height: 68px;
    padding: 12px $HEADER_FOOTER_GUTTER_MOBILE;
  }

  $HEADER_ITEM_HEIGHT: 44px;

  .limiter {
    max-width: $MAX_HEADER_FOOTER_CONTENT_WIDTH;
    margin: auto;
  }

  .navigationBar {
    display: flex;
    align-items: center;
  }

  button {
    width: $HEADER_ITEM_HEIGHT;
    font-size: 0;
    padding: 0;
  }

  .headerItems {
    width: 50%;
    display: flex;
    gap: 16px;
    height: $HEADER_ITEM_HEIGHT;
    justify-content: flex-end;

    // search bar
    form {
      display: flex;
      flex-grow: 1;
      position: relative;
    }

    .toggleMenu {
      svg {
        width: 24px;
        height: 24px;
        margin: auto;
      }
    }

    @include mobile {
      #toTPLink {
        display: none;
      }
    }
  }
}

.navigationList {
  padding: 34px 0;

  @include mobile {
    padding-top: 0;
  }

  .menuItem {
    display: list-item;
    margin: 0;
    width: 100%;
    $CONTENT_AREA_PADDING_TOP_BOTTOM: 11px;

    border-left: $ACCORDION_SIDE_BORDER_WIDTH solid $BACKGROUND;
    padding: $CONTENT_AREA_PADDING_TOP_BOTTOM 24px $CONTENT_AREA_PADDING_TOP_BOTTOM calc(24px - #{$ACCORDION_SIDE_BORDER_WIDTH});
    font-weight: $MEDIUM;

    &.isActive {
      border-color: $SECONDARY;
      background-color: $YELLOW_01;
    }

    &:hover {
      border-color: $PRIMARY;
    }

    &:before {
      display: none;
    }

    .menuLink {
      text-decoration: none;
      margin-top: 0;
      color: $BLACK !important;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

.mobileSearchBar {
  padding: 24px 16px 32px;

  // TODO: may have to be changed if switching to fieldset
  div {
    height: 44px;

    button {
      margin: 0 !important;
    }
  }
}
