/*
  SideNavigation.module.scss

  This file contains all scoped style for SideNavigation.jsx.
*/

@import '../../Styles/global';

#sideNavTop {
  display: grid;
  grid-template-columns: 1fr 44px;
  margin-bottom: 24px;
  align-items: center;
  word-break: break-word;

  #toCoverPage {
    color: $BLACK;
  }

  #navigationToggleBtn {
    display: block;
    font-size: 0;
    color: $BLACK;
    background-color: transparent;
    position: relative;
    height: 44px;
    grid-column: 2;

    @include desktop {
      width: 100%;
    }

    svg {
      width: 12.9px;
      height: 15.15px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background-color: $GREEN_04;
      color: $WHITE;
    }

    &[aria-expanded='true'] {
      svg {
        transform: translate(-50%, -50%) scale(-1);
      }
    }
  }

  // only shown in article cover page
  &.inCoverPage {
    display: block;
    text-align: end;

    #navigationToggleBtn {
      display: inline-flex;
      font-size: font-size(0);
      gap: 14px;
      text-decoration: underline;
      align-items: baseline;

      svg {
        position: static;
        transform: none;
      }

      &[aria-expanded='true'] {
        svg {
          transform: scale(-1);
        }
      }
    }
  }

  // style only for mobile look in article page
  &.mobileView {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $GREEN_01;
    padding-left: 20px;
    border-radius: 4px;
    font-weight: $SEMI_BOLD;
    font-size: font-size(-1);
    margin-bottom: 0;

    button {
      width: 44px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-left: 5.775px solid transparent;
        border-right: 5.775px solid transparent;
        border-top: 5.77px solid $PRIMARY;
      }

      &:hover:before {
        border-top-color: $WHITE;
      }
    }
  }
}

#sideNavigation {
  margin-bottom: 24px;

  ol.menuContainer {
    display: block;
    padding-left: 0;
    $CONTENT_AREA_PADDING_TOP_BOTTOM: 11px;

    %listItemStyle {
      border-left: $ACCORDION_SIDE_BORDER_WIDTH solid $BACKGROUND;
      padding: $CONTENT_AREA_PADDING_TOP_BOTTOM 24px $CONTENT_AREA_PADDING_TOP_BOTTOM calc(24px - #{$ACCORDION_SIDE_BORDER_WIDTH});
      font-weight: $MEDIUM;
    }

    & > li {
      display: list-item;
      margin: 0;
      width: 100%;

      &.noChild {
        @extend %listItemStyle;

        a {
          color: $BLACK;
        }

        &.isActive {
          border-color: $SECONDARY;
          background-color: $YELLOW_01;
        }

        &:hover {
          border-color: $PRIMARY;
          background-color: $BACKGROUND;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        display: none;
      }

      li {
        @extend %listItemStyle;
        padding-left: 36px;

        &:hover {
          background-color: $GREEN_01;
          border-left-color: $PRIMARY;
        }

        &.isActive {
          background-color: $YELLOW_01;
          border-left-color: $SECONDARY;

          &:hover {
            background-color: $YELLOW_02;
            border-left-color: $YELLOW_03;
          }
        }
      }
    }

    .item,
    a {
      font-size: font-size(-1);
      text-decoration: none;

      &:hover {
        color: $BLACK !important;
        text-decoration: underline !important;
      }
    }

    &.childNav {
      background-color: $GREY_01;

      .item,
      a {
        color: $PRIMARY;
        font-weight: $REGULAR;

        &[aria-current='true'] {
          color: $BLACK;
          text-decoration: underline;
        }
      }
    }
  }
}

#mobileSideNavModal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: $SKIP_BUTTON_Z_INDEX + 1;
  padding: $MIN_CONTENT_SIDE_PADDING;

  & > div {
    background-color: $WHITE;
    padding-bottom: 24px;
    border-radius: 4px;
    box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 40px); // 20px of padding top and bottom

    #sideNavTop {
      margin-bottom: 16px;

      #navigationToggleBtn {
        &:before,
        &:after {
          display: block;
          content: '';
          border: 0;
          width: 21.07178px;
          height: 2px;
          background-color: $PRIMARY;
          position: absolute;
          top: 50%;
          left: 50%;
          transform-origin: center;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:hover {
          &:before,
          &:after {
            background-color: $WHITE;
          }
        }
      }
    }

    #sideNavigation {
      max-height: calc(100vh - 100px); // 40px of padding and 60px size of header
      overflow-y: auto;

      .noChild {
        padding: 11px 20px 11px 16px;
      }

      a {
        font-size: font-size(-1);
      }
    }
  }
}

// Animations
.sideNavAppear {
  position: relative;
  animation: slideUp 0.5s ease-out;

  @keyframes slideUp {
    0% {
      top: 100vh;
    }
    100% {
      top: 0;
    }
  }
}

.sideNavExit {
  position: relative;
  animation: slideDown 0.5s ease-out;

  @keyframes slideDown {
    0% {
      top: 0;
    }
    100% {
      top: 100vh;
    }
  }
}

.fadeIn {
  @keyframes fade-in {
    0% {
      opacity: 0.2;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.6;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 0.8s ease-in;
}

.fadeOut {
  @keyframes fade-out {
    0% {
      opacity: 0.8;
    }
    25% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
  animation: fade-out 0.8s ease-in;
}

.tagWrapper{
  display:flex;
  background-color:#EFF5F5;
  padding: 0px 0px 10px 20px;
}
