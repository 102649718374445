/*
  PublicationsLoading.module.scss

  This file contains all scoped style for PublicationsLoading.jsx.
*/

@import '../../Styles/global';

.bannerHead {
  max-width: 808px;

  p {
    font-size: font-size(-1);
  }
}

#publicationsLoading {
  .publicationsList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 32px;

    @include tablet {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    .cardTile {
      min-height: 391px;

      @include mobile {
        min-height: 172px;
      }
    }
  }

  .paginationLoading {
    div {
      height: 44px;
    }
  }
}
