/*
  AnnualReportCoverLoading.module.scss

  This file contains all scoped style for AnnualReportCoverLoading.jsx.
*/

@import '../../Styles/global';

#reportTitle {
  display: flex;
  gap: 16px;
  flex-direction: column;

  @include tablet {
    margin-bottom: 24px;
  }

  .loadingFullHeight {
    height: 100%;
  }
}

#cover {
  display: grid;
  grid-template-columns: 1fr minmax(200px, 496px);
  column-gap: 10.4575%;

  .loadingCoverImg {
    height: 700px;
  }

  @include tablet {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;

    .loadingCoverImg {
      width: 50%;
      height: 400px;
    }
  }

  @include mobile {
    .loadingCoverImg {
      width: 100%;
      height: 200px;
    }
  }
}

.loadingBreadcrumb {
  min-height: 24px;
  margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;
}

.loadingBanner {
  min-height: 240px;
  margin-bottom: 48px;

  @include mobile {
    margin-bottom: 40px;
  }
}

.invisibleTitle {
  opacity: 0.001;
}

.loadingSideNavigation {
  min-height: 200px;

  @include mobile {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

.loadingRelevantLinks {
  display: grid;
  padding: 0;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @include mobile {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  & > li {
    display: grid;
    &:before {
      display: none;
    }
  }
}
