/*
  HomePage.module.scss

  This file contains all style for HomePage.jsx.
  All scoped style within home page can be found here.
*/

@import '../../Styles/global';

main {
  padding-top: 0 !important;

  [class='card'] {
    padding-bottom: 16px;
  }
}

#hero {
  display: flex;
  flex-direction: column;
  gap: 140px;
  padding: 120px 0 60px;
  position: relative;

  // tree background desktop only
  @media screen and (min-width: $DESKTOP_MIN) {
    .backgroundImage {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;

      .imageContainer {
        position: absolute;
        bottom: 0;
        right: 46px;
        width: 600px;
        height: 100%;

        .tree {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background-image: var(--BACKGROUND-IMAGE);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 0 60px;
          filter: blur(8px);
        }

        &.transparentBackground {
          $CLIP_PATH: circle(278px at calc(50% - 10px) calc(50% - 40px));
          background: transparent;

          .tree {
            filter: none;
            clip-path: $CLIP_PATH;
            animation: maskSlide 2.5s ease-in-out;
            background-color: $GREEN_01;

            @keyframes maskSlide {
              0% {
                clip-path: circle(278px at calc(120% - 10px) calc(50% - 40px));
              }

              100% {
                clip-path: $CLIP_PATH;
              }
            }
          }
        }
      }
    }
  }

  #contentArea {
    max-width: 406px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    * {
      margin: 0;
      color: $BLACK;
    }

    h1 {
      padding: 0;
      border-bottom: 0;
      margin-bottom: 0;
      position: relative;
      transform: translateX(-12px);

      span {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.1px;
        opacity: 0.0001;
      }
    }

    #jumpToPublicationsList {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      svg {
        color: inherit;
        transform: scale(-1);
        width: 24px;
        height: 24px;
        padding: 2px;
      }
    }
  }

  #quickLinks {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;

    a {
      max-height: none;
    }
  }

  @include tablet {
    .backgroundImage {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 100svw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $BACKGROUND;
        background-image: var(--BACKGROUND-IMAGE);
        background-size: auto;
        background-repeat: no-repeat;
        background-position: 50% 320px;
      }

      &:before {
        filter: blur(8px);
      }

      &:after {
        clip-path: circle(60% at 50% 0);
      }
    }
  }

  @include mobile {
    padding: 40px 0 48px;
    gap: 110px;

    #contentArea {
      h1 {
        transform: none;

        svg {
          height: 70px;
        }
      }

      font-size: font-size(0);
    }

    #quickLinks {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
}

%sectionStyle {
  padding: 48px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  h2 {
    margin: 0;
    grid-column: 1/4;
  }

  &:not([class*='fullColorBackground']) {
    background-color: $WHITE;
  }

  &[style*='--BACKGROUND-IMAGE'] {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      background-image: var(--BACKGROUND-IMAGE);
      background-repeat: no-repeat;
      background-size: contain;

      @include mobile {
        display: none;
      }
    }
  }

  @include mobile {
    grid-template-columns: 1fr;
    padding: 56px 0;

    h2 {
      grid-column: unset;
    }
  }
}

#publicationsList {
  @extend %sectionStyle;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;

  &>.publicationTypeWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #005562;

    &>.accordianButton{
      display: flex;
      width: 100%;
      height: 56px;
      padding-left: 24px;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;
      border-radius: 4px 4px 0px 0px;

      background: #E1ECEB;

      .buttonTextWrapper{
        display: flex;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        &>h3{
          color: #000;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          margin: 0;
        }
      }

      .buttonArrowWrapper{
        display: flex;
        height: 56px;
        padding: 10px;
        align-items: center;
        gap: 10px;

        &>svg{
          width: 24px;
          height: 24px;
          transform: rotate(180deg);
        }
        .arrowRotated {
          transform: rotate(360deg);
        }
      }

      &:active {
        background-color: initial; 
        color: initial; 
        border: initial; 
      }
    }
    &>.openButtonAccordian{
      border-bottom: 1px solid #005562;
    }


    &>.accordianButton:focus{
      z-index: 1;
      outline-color: transparent;
      outline-style: solid;
      outline-width: 0;
    }

    &>.accordianTextWrapper{
      display: flex;
      padding: 10px 10px 10px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    &>.accordianLinkWrapper{
      display: flex;
      width: 244px;
      padding: 10px 10px 20px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      &>.link{
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 2px;
        background-color: #005562;
        &>p{
          color: #FFF;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .accordianButton{
      justify-content: flex-end;
      align-self: stretch;

      .buttonTextWrapper{
        padding-left: 4px;
        align-self: stretch;
        text-align: start;
        &>h3{
          flex: 1 0 0;
        }
      }


    }
  }

  @include tablet {
    grid-template-columns: 1fr;

    h2 {
      grid-column: 1;
    }
  }
}

#relatedResources {
  @extend %sectionStyle;

  // targeting cards
  & > [class*='card'] {
    min-height: 166px;
  }
}
