/*
  AnnualReportBanner.module.scss

  This file contains all scoped style for AnnualReportBanner.jsx.
*/

@import '../../Styles/global';

#AnnualReportBanner {
  position: relative;
  display: grid;
  grid-template-columns: 308px 1fr;

  --default-banner-background-size: 350px;
  --default-banner-background-clip: circle(140px);

  @include desktop {
    min-height: 240px;
  }

  @include tablet {
    grid-template-columns: 220px 1fr;
  }

  // banner image
  .backgroundContainer {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    // only used for default background to provide blur effect
    .bannerBlurBackground {
      background-image: var(--background-banner);
      filter: blur(2px);
      background-position-y: bottom;
      background-position-x: center;
      background-repeat: no-repeat;
      width: 100%;
      grid-row: 1;
      grid-column: 1;
      z-index: 1;
      background-size: var(--default-banner-background-size);

      @include mobile {
        --default-banner-background-size: 180px;
      }
    }

    .bannerBackground {
      background-image: var(--background-banner);
      background-position-y: center;
      background-position-x: center;
      background-repeat: no-repeat;
      background-size: contain; // set to contain to make sure image is always there
      width: 100%;
      grid-row: 1;
      grid-column: 1;
      z-index: 2;
      background-color: $GREEN_01;

      // the default background is clipped to view certain section
      &.default {
        clip-path: var(--default-banner-background-clip);
        background-size: var(--default-banner-background-size);
        background-position-y: bottom;
        background-color: $GREEN_01;

        @include mobile {
          --default-banner-background-size: 180px;
          --default-banner-background-clip: circle(65px);
        }
      }
    }
  }

  .brandMark {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: $SIMPLE_BANNER_BRAND_MARK_WIDTH;
    padding: 24px 0;
    justify-content: center;

    * {
      margin-bottom: 0;
    }

    .logoWrapper {
      background-color: $GREEN_01;
      border: 1px solid $GREY_03;
      border-radius: 4px;
      max-width: 180px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 120px;
        padding: 8px;

        @include mobile {
          // width: 100%;
          max-height: 100%;
        }
      }
    }

    .brandInfo {
      a {
        color: $BLACK;

        &:visited {
          color: $BLACK;

          &:hover {
            color: $BLACK;
          }
        }
      }

      .portfolio {
        a,
        span {
          display: -webkit-inline-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }

  @include tablet {
    margin-bottom: 48px;
  }

  @include mobile {
    margin-bottom: 40px;
    $MOBILE_BANNER_IMAGE_HEIGHT: 80px;
    gap: 0;
    width: calc(100% + ($MIN_CONTENT_SIDE_PADDING * 2));
    left: -$MIN_CONTENT_SIDE_PADDING;
    display: grid;
    grid-template-columns: 120px 1fr;

    &:after {
      height: $MOBILE_BANNER_IMAGE_HEIGHT;
      order: 1;
    }

    .brandMark {
      display: contents;

      .logoWrapper {
        border: $WHITE;
        width: 120px;
        height: $MOBILE_BANNER_IMAGE_HEIGHT;
        border-radius: 0;
        order: 0;
      }

      .brandInfo {
        order: 2;
        grid-column: 1 / -1;
        padding: 8px 20px;
      }
    }
  }
}

@include print {
  #AnnualReportBanner {
    display: none;
  }
}
