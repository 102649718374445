/*
  AlphabetGroupFilterList.module.scss

  This file contains all scoped style for AlphabetGroupFilterList.jsx.
*/

@import '../../../Styles/global';

.alphabetFilterList {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  margin: 32px;
  margin-bottom: 32px !important; // Added to overwrite last child global style
  column-gap: 24px;
  max-height: calc(500px - 64px); // 64 px for twice the side margin and +2px for border width (1px each)
  max-width: calc(720px - 66px); // 64 px for twice the side margin and +2px for border width (1px each)

  @include mobile {
    display: block;
    margin: 0 !important;
    width: 100%;
    max-height: fit-content;
  }

  &.hideAlphabetButtons {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .alphabetButtons {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, 32px);
    gap: 12px;

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      vertical-align: middle;
      font-size: font-size(0);
    }
  }

  .searchList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: calc(500px - 66px); // 64 px for twice the side margin and +2px for border width (1px each)

    @include mobile {
      max-height: calc(100vh - 175px);
    }

    .searchBarContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include mobile {
        padding: 0 16px 16px 16px;
      }
    }

    .checkboxLabel {
      display: grid;
      grid-template-columns: auto 1fr;
      padding-bottom: 16px;
    }
  }
}
