/*
  StandardPageLoading.module.scss

  This file contains all style for StandardPageLoading.jsx.
  All scoped style within page not found can be found here.
*/

@import '../../Styles/global';

.loadingBody {
  height: 200px;
  margin-bottom: 1rem;
}
