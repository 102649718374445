/*
  PdfViewer.module.scss

  This file contains all scoped style for PdfViewer.jsx.
*/

@import '../../Styles/global';

*:not(:empty) ~ .pdfViewer,
*:not(:empty) ~ .loading {
  margin-top: 1rem;
}

.pdfViewer {
  border: 1px solid $GREY_04;
  overflow: hidden;
  border-radius: 4px;
  $MENU_HEIGHT: 44px;
  $PDF_MAX_HEIGHT: 75svh;

  .menu {
    width: 100%;
    height: 60px;
    display: flex;
    background: $GREY_01;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12px);
    padding: 8px 8px 8px 16px;

    .inputWrapper {
      padding: 10px 12px;
      border-radius: 2px;
      border: 1px solid $GREY_04;
      display: inline-flex;

      input[type='number'] {
        -moz-appearance: textfield;
        text-align: center;
        padding: 1px;
        border: none;
        box-sizing: border-box;
        font-size: font-size(0);
        background-color: transparent;
        min-width: 34.5px;

        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      span {
        min-width: 34.5px;
        display: inline-flex;
        justify-content: center;
      }
    }

    #pageZoomControl {
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 65px;

      input {
        display: inline-flex;
        max-width: 40px;
      }

      @include mobile {
        display: none;
      }
    }

    #pageNavigationControl {
      margin: auto;

      input {
        width: 34.5px;
      }

      @include mobile {
        margin-left: 0;
      }
    }

    #download {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .documentWrapper {
    overflow: overlay;
    height: $PDF_MAX_HEIGHT;
    background-color: $GREY_02;
    padding: 16px;

    .document {
      position: relative;
    }

    %messages {
      min-height: $PDF_MAX_HEIGHT;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .resizingIndicator {
      @extend %messages;
      display: none;
    }

    [class*='react-pdf__message'] {
      @extend %messages;
      position: relative;
    }

    [class='react-pdf__Page'] {
      position: relative;
      background-color: transparent !important;

      & > div,
      & > [class='react-pdf__Page__canvas'] {
        left: 50%;
        transform-origin: center;
        transform: translateX(-50%);
      }

      & > [class='react-pdf__Page__canvas'] {
        position: relative;
      }
    }

    @include mobile {
      padding: 16px 12px;
    }
  }

  .loadingIndicator {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #{$GREEN_01}99;
  }

  .outlineWrapper {
    background-color: $WHITE;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 222;
    width: 30%;
    height: calc(100%);
    overflow: scroll;
  }

  @include print {
    display: none;

    .menu {
      display: none;
    }
  }
}

.loading {
  width: 100%;
  height: 80svh;
}

.notFoundText {
  text-align: center;
  margin: 0;
}
