/*
  GroupedFilterList.module.scss

  This file contains all scoped style for GroupedFilterList.jsx.
*/

@import '../../../Styles/global';

.alphabetListSelect {
  overflow: auto;
  border-top: 2px solid $GREEN_02;
  border-bottom: 2px solid $GREEN_02;
  min-height: 100px;
  width: 100%;

  @include mobile {
    border-bottom: none;
  }

  .alphabetHeader {
    position: sticky;
    top: 0;
    background-color: $WHITE;
    font-size: font-size(1);
    font-weight: $SEMI_BOLD;
    padding: 8px 60px; // 60px comes from padding left (16px), checkbox size (24px), margin of checkbox (4 + 16)
  }

  .noResults {
    margin-top: 25px;
    padding: 0 20px;
  }
}
