/*
  _element.scss

  This file contains all element styles such as button and card
*/
/*
===== TABLE OF CONTENT =====
1. BUTTON
2. CARD
3. SUPERSCRIPT
4. FIGURE
5. CHECKBOX
6. TEXT INPUT
7. RADIO BUTTONS
8. FORM
*/

/*==============================================================
1. BUTTON
==============================================================*/
/*
by default button will have "Dark fill" style.

different styles can be applied by adding following styles
  - lightFill
  - secondary (yellow theme)
  - outline
  - noFill
*/
.buttonStyle,
button,
a.btn,
input[type='submit'],
input[type='reset'],
input[type='clear'] {
  // primary button style
  border-radius: 2px;
  border: 1px solid transparent;
  padding: 10px;
  cursor: pointer;
  background-color: $PRIMARY;
  color: $WHITE;
  text-align: center;
  font-size: inherit;
  line-height: inherit;

  // light fill button style
  &.lightFill {
    $LIGHT_FILL_TEXT_COLOR: $BLACK !important;
    background-color: $BACKGROUND;
    color: $LIGHT_FILL_TEXT_COLOR;
    border-style: solid;
    border-color: $GREEN_03;
    border-width: 1px;

    &:focus {
      background-color: $GREY_01;
    }

    &:hover:not(:disabled, .disabledState, :active, .secondary, .activeState) {
      border-color: $PRIMARY;
      background-color: $GREEN_02 !important;
      color: $LIGHT_FILL_TEXT_COLOR;
    }

    &:visited {
      color: $LIGHT_FILL_TEXT_COLOR;

      &:hover:not(:disabled, .disabledState, :active, .secondary, .activeState) {
        color: $LIGHT_FILL_TEXT_COLOR;
      }
    }

    &:disabled,
    &.disabledState {
      background-color: $GREY_02 !important;
      cursor: not-allowed !important;
      text-decoration: line-through !important;
      color: $GREY_04 !important;
      border-color: transparent !important;
    }
  }

  // outline button style
  &.outline {
    $OUTLINE_TEXT_COLOR: $BLACK !important;
    $OUTLINE_TEXT_DECORATION: underline !important;
    border: 1px solid $GREY_04;
    background-color: $WHITE;
    color: $OUTLINE_TEXT_COLOR;
    text-decoration: $OUTLINE_TEXT_DECORATION;

    &:focus {
      background-color: $WHITE;
    }

    &.secondary,
    &.activeState,
    &:active {
      border-color: $SECONDARY;
      background-color: $WHITE;
    }

    &:hover:not(:disabled, .disabledState, :active, .secondary, .activeState) {
      background-color: $BACKGROUND !important;
      border-color: $GREEN_04 !important;
      color: $OUTLINE_TEXT_COLOR;
    }

    &:visited {
      color: $OUTLINE_TEXT_COLOR;

      &:hover:not(:disabled, .disabledState, :active, .secondary, .activeState) {
        color: $OUTLINE_TEXT_COLOR;
      }
    }

    &:disabled,
    &.disabledState {
      color: $GREY_05 !important;
      background-color: $WHITE !important;
      border-color: $GREY_05 !important;
      text-decoration: none !important;
    }
  }

  // no fill button style
  &.noFill {
    color: $BLACK;
    font-family: inherit;
    font-size: inherit;
    text-decoration: underline;
    background-color: transparent;
    cursor: pointer;

    &:hover:not(:disabled, .disabledState, :active, .secondary, .activeState) {
      color: $PRIMARY !important;
      background-color: $BACKGROUND !important;
      text-decoration: underline !important;
      text-decoration-color: $PRIMARY !important;
    }

    &.secondary,
    &.activeState,
    &:active {
      color: $ACCEPTED;
      background-color: transparent;

      &:hover:not(:disabled, .disabledState) {
        text-decoration: underline !important;
      }
    }

    &:visited {
      color: $BLACK;

      &:hover:not(:disabled, .disabledState, :active, .secondary, .activeState) {
        color: $PRIMARY !important;
        background-color: $BACKGROUND !important;
      }
    }

    &:disabled,
    &.disabledState {
      color: $GREY_05 !important;
      text-decoration: line-through !important;
      background-color: transparent !important;
    }
  }

  // secondary button style
  &.secondary,
  &.activeState,
  &:active {
    background-color: $SECONDARY;
    color: $BLACK !important;
    border: 1px solid $YELLOW_03;

    &:visited {
      color: $BLACK;
    }

    &:hover:not(:disabled, .disabledState) {
      background-color: $YELLOW_02;
      color: $BLACK !important;
    }
  }

  &:focus {
    background-color: $GREY_01;
    color: $BLACK !important;
  }

  &:hover:not(:disabled, .disabledState) {
    background-color: $GREEN_04;
    color: $WHITE !important;
  }

  &:visited {
    color: $WHITE;
    &:focus {
      color: $BLACK !important;
    }

    &:hover {
      color: $WHITE !important;
    }
  }

  &:disabled,
  &.disabledState {
    background-color: $GREY_02 !important;
    cursor: not-allowed !important;
    color: $GREY_04 !important;
  }

  &:is(a) {
    text-decoration: none;
  }

  &.skipButton,
  &.compareButton {
    padding: 9px;
    position: fixed;
    font-size: 0;
    width: 44px;
    min-height: 44px;
    border-radius: 22px;
    border: 1px solid $WHITE;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: $SKIP_BUTTON_Z_INDEX;

    @include print {
      display: none;
    }

    svg {
      width: 16.4px;
      height: 16.4px;
    }

    &:hover {
      color: $WHITE !important;
    }

    // skip to content button is noramlly hidden and only shown when focused
    &.toContent {
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      transition: all $TRANSITION_STYLE $TRANSITION_TIMING;
      margin-bottom: -44px;

      svg {
        transform: rotate(180deg);
      }

      span {
        display: block;
        position: absolute;
        color: $BLACK;
        font-size: font-size(0);
        bottom: 80%;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
        opacity: 0;
        font-size: 0; // FIXME: hiding for now to discuss with designer
      }

      &:focus {
        top: 40px;

        span {
          opacity: 1;
          bottom: calc(100% + 4px);
          transition: all linear $TRANSITION_TIMING $TRANSITION_TIMING;
        }
      }
    }

    /* 
    back to top button is sticky and alwasy stay at bottom right side.
    in desktop, it is staying outside of content area
    */
    &.toTop,
    &.compareButton {
      $BUTTON_TRANSFORM_X_AMOUNT: 75.5px;

      &:not(.hidden) {
        position: sticky;
        bottom: 44px;
        margin-left: auto;
        transform: translateX(#{$BUTTON_TRANSFORM_X_AMOUNT});
        margin-bottom: -44px !important;
        transition: all $TRANSITION_STYLE $TRANSITION_TIMING;
        height: 44px;
        width: revert;
        white-space: nowrap;
        justify-content: flex-end;
        overflow: hidden;
        gap: 10px;
        max-width: 44px;
        transition: none;

        span {
          font-size: initial;
          word-break: none;
        }

        svg {
          overflow: visible;
          width: 24px;
          height: 24px;
        }

        &:hover,
        &:focus {
          @media screen and (min-width: $TABLET_MIN) {
            transition: max-width ease-in 450ms;
            max-width: 100%;
            padding-left: 12px;
          }
        }

        @media screen and (max-width: #{$MAX_CONTENT_WIDTH + ($MIN_CONTENT_SIDE_PADDING * 2) + 150px}) {
          transform: translateX(0);
        }
      }

      &:is(.compareButton) {
        transform: translateX(#{$BUTTON_TRANSFORM_X_AMOUNT}) translateY(-52px);

        @media screen and (max-width: #{$MAX_CONTENT_WIDTH + ($MIN_CONTENT_SIDE_PADDING * 2) + 150px}) {
          transform: translateX(0) translateY(-52px);
        }

        @include mobile {
          max-width: 100%;
          transform: translateX(0) translateY(0);
          margin-left: 0;
          margin-right: auto;
          flex-direction: row-reverse;
          gap: 2px;

          svg {
            width: 20px;
            height: 20px;
          }

          span {
            font-size: font-size(-1);
          }
        }
      }

      &.hidden {
        position: absolute;
        right: 50%;
        bottom: 0;
        transform: translateX(-50%);
        opacity: 0;
        transition: all $TRANSITION_STYLE $TRANSITION_TIMING;
        z-index: calc($HEADER_Z_INDEX * -1);

        &:focus {
          bottom: 44px;
          opacity: 1;
          z-index: $HEADER_Z_INDEX;
        }
      }
    }
  }
}

/*==============================================================
2. CARD
==============================================================*/
.card {
  position: relative;
  padding: 16px 16px 60px 24px;
  border: 1px solid $GREY_04;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: $BLACK !important;
  font-weight: $BOLD;
  background-color: $WHITE;
  font-size: font-size(-1);

  &:before {
    display: block !important;
  }

  a {
    color: $BLACK !important;
    text-decoration: none;
  }

  span {
    text-decoration: none;
  }

  &:before {
    display: block;
    content: '';
    width: 8px;
    height: 100%;
    padding: 0;
    background-color: $BACKGROUND;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:focus {
    color: $BLACK !important;
    border-color: $SECONDARY;

    &:before {
      background-color: $SECONDARY;
    }
  }

  &:hover,
  &:focus-within {
    color: $BLACK !important;
    border-color: $PRIMARY;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);

    &:before {
      background-color: $PRIMARY;
      transition: background-color $CARD_TRANSITION_TIMING ease;
    }
  }

  .cardTitle {
    font-weight: $SEMI_BOLD;
    text-decoration: underline;

    &[style*='--MAX-LINE'] {
      @include mobile {
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: var(--MAX-LINE);
        overflow: hidden;
      }
    }
  }

  .cardTitleWhite {
    font-weight: $SEMI_BOLD;
    text-decoration: underline;
    color: $WHITE !important;
  }

  .description {
    font-weight: $REGULAR;
  }

  .newTabIconWrapper {
    display: inline-grid;
    grid-template-columns: 1fr 24px;
    width: 100%;
  }

  .headerLink {
    text-decoration: underline;
    display: inline !important;
  }

  .limitTextLines {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--MAX-LINE);
    overflow: hidden;
  }

  .tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    text-decoration: none;

    @include mobile {
      gap: 4px;
    }
  }

  &.listItem {
    $CARD_PADDING: 16px;
    $CARD_RIGHT_PADDING: 24px;
    height: 100%;
    padding: $CARD_PADDING $CARD_RIGHT_PADDING $CARD_PADDING $CARD_PADDING;
    transition: all linear $TRANSITION_TIMING;
    overflow: hidden;

    &.leadingEntity {
      background-color: $PRIMARY;
      border-color: $PRIMARY !important;
      color: $WHITE;

      &:hover,
      &:focus-within {
        background-color: $GREEN_04;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);

        &:before {
          transition-duration: 0;
        }
      }

      &:before {
        background-color: $PRIMARY;
      }
    }

    &:before {
      opacity: 0;
      transition: all linear $CARD_TRANSITION_TIMING;
      z-index: 2;

      @include mobile {
        width: 4px;
      }
    }

    &:hover,
    &:focus,
    &:focus-within {
      &:before {
        opacity: 1;
      }
    }

    .portfolioInfo {
      font-weight: $REGULAR;
      padding-bottom: $CARD_PADDING;
      margin-bottom: 19px;
      position: relative;
      z-index: 1;
      text-decoration: none;

      &:before {
        display: block;
        content: '';
        background-color: $GREEN_01;
        position: absolute;
        top: -$CARD_PADDING;
        left: -20%;
        width: 150%;
        height: calc(100% + #{$CARD_PADDING});
        z-index: -1;
      }
    }

    .tags {
      margin-bottom: 8px;
    }

    .cardTitle {
      margin-bottom: 24px !important;
      display: block;
      font-size: font-size(1);
      word-wrap: break-word;

      @include mobile {
        margin-right: 26px;
      }

      svg {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        margin-left: 4px;
      }
    }

    img,
    .imgLoading {
      width: 156px;
      aspect-ratio: 0.7;
      border: 1px solid $GREY_03;
      border-radius: 4px;
    }

    @include mobile {
      height: fit-content;

      .portfolioInfo {
        grid-column: 1/3;
        margin-top: -4px;
        margin-bottom: 12px;
        padding-bottom: 8px;
      }

      img,
      .imgLoading {
        width: 80px;
      }

      a {
        display: grid;
        grid-template-columns: 1fr 80px;
      }
    }

    &.entityItem {
      padding-right: 8px;
      min-height: 132px;

      @include mobile {
        padding: 8px;
      }

      .entityLink {
        display: grid;
        grid-template-columns: 1fr max-content;
        gap: 16px;
        height: 100%;

        @include mobile {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .imgContainer {
          height: 86px;
          width: 128px;
          border: 1px solid $GREY_03;
          background-color: $GREEN_01;
          align-self: flex-start;
          border-radius: 4px;
          padding: 8px;
          position: relative;
          top: 50%;
          transform: translateY(-50%);

          @include mobile {
            top: revert;
            transform: revert;
            align-self: flex-end;
            height: 54px;
            width: 80px;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            border: none;
          }
        }
      }
    }
  }

  // if card is used as content item, always render in two columns
  &.inBodyContent {
    max-width: calc(50% - 24px);
    float: left;
    margin-bottom: 24px !important;
    padding-bottom: 16px;
    height: 166px;
    margin-right: 24px;

    &.maxWidth {
      max-width: none;
      width: 100%;
      float: none;
    }

    &:nth-child(odd) {
      margin-right: 24px;
    }

    &:last-child,
    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    @include tablet {
      margin-bottom: 16px;
      max-width: calc(50% - 16px);

      &:nth-child(odd) {
        margin-right: 16px;
      }
    }

    @include mobile {
      float: revert;
      max-width: revert;
      height: 122px;

      &:nth-child(odd) {
        margin-right: 0;
      }

      &:nth-last-child(2) {
        margin-bottom: 16px;
      }
    }
  }
}

/*==============================================================
3. SUPERSCRIPT
==============================================================*/
sup {
  vertical-align: top;
}

/*==============================================================
4. FIGURE
==============================================================*/
figure {
  overflow-x: overlay;

  figcaption {
    color: $GREY_05;
    text-align: left;
  }
}

/*==============================================================
5. CHECKBOX
===============================================================*/
[type='checkbox'] {
  appearance: none;
  color: $WHITE;
  width: 24px;
  height: 24px;
  border: 2px solid $GREY_04;
  border-radius: 2px;
  vertical-align: middle;
  margin-right: 16px;
  background-color: $WHITE;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 5px;
    height: 12px;
    transform: scale(0) rotate(45deg);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    border: solid white;
    border-width: 0 3px 3px 0;
    left: 10%;
    top: -20%;
    position: relative;
  }

  &:checked {
    background-color: $PRIMARY;
    &:before {
      transform: scale(1) rotate(45deg);
    }
  }

  &:hover {
    &:not(:checked, :disabled) {
      background-color: $GREEN_01;
      border: 2px solid $GREEN_04;
    }
  }

  &.dashCheckbox {
    &:checked {
      background-color: $PRIMARY;
      &:before {
        transform: scale(1) rotate(90deg);
        border-width: 0 3px 0 0;
        left: 20%;
        top: -40%;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

/*==============================================================
6. TEXT INPUT
===============================================================*/
input {
  &[type='search'],
  &[type='email'],
  &[type='date'],
  &[type='datetime-local'],
  &[type='number'],
  &[type='password'],
  &[type='tel'],
  &[type='time'],
  &[type='url'],
  &[type='week'],
  &[type='text'] {
    flex-grow: inherit;
    padding: 10px;
    padding-right: 54px;
    border-color: $GREY_04;
    border-style: solid;
    font-size: font-size(0);
    border-radius: 2px;
    border-width: 1px;

    &::placeholder {
      color: $GREY_05;
    }

    &.error,
    &:hover {
      border-width: 2px;
      padding: 9px;
    }

    &.error {
      border-color: $ERROR;
    }

    &:hover {
      border-color: $PRIMARY;
    }

    &:is([type='search']) {
      border-radius: 2px 0 0 2px;
      border-width: 1px 0 1px 1px;

      &:hover {
        border-width: 2px 0 2px 2px;
      }

      &::-webkit-search-cancel-button {
        display: none;
      }

      &::placeholder {
        color: $BLACK;
      }
    }
  }
}

textarea {
  display: flex;
  padding: 10px 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid $GREY_04;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: $REGULAR;
  line-height: 24px;
  resize: vertical;

  &::placeholder {
    color: $GREY_05;
  }

  &.error,
  &:hover {
    border-width: 2px;
    padding: 9px 11px;
  }

  &.error {
    border-color: $ERROR;
  }

  &:hover {
    border-color: $PRIMARY;
  }

  &:disabled {
    &:hover {
      border-radius: 2px;
      border: 1px solid $GREY_04;

      &::placeholder {
        color: $GREY_05;
      }
    }
  }
}

/*==============================================================
7. RADIO BUTTONS
===============================================================*/
input[type='radio'] {
  appearance: none;
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 2px solid $GREY_04;
  background: $WHITE;
  margin-right: 16px;

  &:checked {
    background: $WHITE;
    border-color: $PRIMARY;
    border-width: 8px;

    &:hover {
      border-color: $PRIMARY !important;
      background: $WHITE !important;
    }
  }

  &:hover {
    border-color: $GREEN_04;
    background: $GREY_01;
  }

  &:disabled {
    background: $GREY_04;
    border-color: $GREY_04;

    &:hover {
      background: $GREY_04 !important;
      border-color: $GREY_04 !important;
    }
  }
}

/*==============================================================
8. FORM
===============================================================*/
fieldset {
  border: none;
  margin: 0 0 24px 0;
  padding: 0;
  display: grid;
  row-gap: 8px;

  label,
  legend {
    font-weight: $MEDIUM;
    font-size: font-size(-1);

    &.required {
      &:after {
        content: ' *';
        display: inline;
        color: $ERROR;
      }
    }
  }

  .fieldDescription {
    font-size: font-size(-1);
    font-weight: $REGULAR;
    color: $GREY_05;

    &.error {
      color: $ERROR;
    }
  }
}
