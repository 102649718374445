/*
  DropdownSelect.module.scss

  This file contains all scoped style for DropdownSelect.jsx.
*/

@import '../../Styles/global';

.dropdownWrapper {
  position: relative;
  background-color: $WHITE;

  .dropdownHeader {
    border: 1px solid $GREY_04;
    border-radius: 2px;
    display: flex;
    align-items: center;

    &.expandedDropdown {
      border-bottom: none;
    }

    input {
      border: none;
      padding: 10px 12px;
      height: 100%;
      width: 100%;
      font-size: font-size(0);

      &:disabled {
        background-color: $WHITE;
      }

      &:hover,
      &:focus {
        padding: 10px 12px;
      }
    }

    select {
      width: 20px;
      margin: 10px !important;
      border: none;

      &.fullSelect {
        width: 100%;
      }

      option {
        &.selected {
          background-color: $BACKGROUND;
        }
      }
    }

    button {
      span {
        font-size: 0;
      }

      background-color: transparent;
      height: 100%;
      width: 44px;
      min-height: 44px;

      &.fullButtonDropdown {
        text-align: left;
        width: 100%;

        span {
          color: $BLACK;
          font-size: font-size(0);
          display: -webkit-box !important;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-right: 24px;
        }
      }

      &.active:not(:disabled) {
        border-color: $SECONDARY;
        background-color: $YELLOW_01;
      }

      &:hover:not(:disabled) {
        background-color: $GREEN_01;
        color: $BLACK;
        border-color: $GREEN_04;
      }

      .placeholder {
        color: $GREY_05 !important;
      }

      &:disabled {
        .placeholder {
          color: $GREY_04 !important;
        }
      }
    }

    // drop down accordion triangle
    .triangle {
      position: relative;

      &:before {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-left: 5.775px solid transparent;
        border-right: 5.775px solid transparent;
        border-top: 5.77px solid $PRIMARY;
        position: absolute;
        top: 50%;
        right: 16.23px;
        transform: translateY(-50%);
      }

      &:disabled {
        &:before {
          border-top-color: $GREY_04;
        }
      }

      &[aria-expanded='true'] {
        &:before {
          transform: translateY(-50%) scale(-1);
        }
      }
    }
  }

  .dropdownOptions {
    position: absolute;
    border: 1px solid $GREY_04;
    border-radius: 0 0 4px 4px;
    z-index: 1;
    width: 100%;
    max-height: 500px;
    overflow: auto;

    &.fixed {
      position: fixed;
    }
  }
}
