/*
  MobileFilter.module.scss

  This file contains all scoped style for MobileFilter.jsx.
*/

@import '../../Styles/global';

.mobileFilterContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-bottom: 2px solid $GREEN_02;

  .apiFailed {
    margin-bottom: 10px !important;
    margin-top: 0;
  }

  button {
    margin-bottom: 16px;
    background-color: $GREEN_01;
    text-align: left;
    text-decoration: underline;
    color: $BLACK;
    font-size: font-size(-1);
    font-weight: $SEMI_BOLD;
    padding: 11px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    span {
      margin-left: 8px;
    }

    .filterIcon {
      float: right;

      svg {
        height: 24px;
        width: 24px;
        color: $PRIMARY;
        vertical-align: middle;
        margin-left: auto;

        &.disabledIcon {
          color: $GREY_05;
        }
      }
    }

    &:focus {
      .filterIcon {
        svg {
          color: $BLACK;
        }
      }
    }

    &:hover {
      color: $BLACK !important;
      background-color: $GREEN_01;

      .filterIcon {
        svg {
          color: $WHITE;
        }
      }
    }

    &:active {
      .filterIcon {
        svg {
          color: $BLACK;
        }
      }
    }
  }

  .filterLabel {
    margin-bottom: 16px !important;
    color: $GREY_05;
  }
}

#mobileFilterPopup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: $SKIP_BUTTON_Z_INDEX + 1;
  padding: $MIN_CONTENT_SIDE_PADDING;
}

.alignIconRight {
  float: right;
  margin-left: auto !important;
}

.filterPopupHeader {
  padding: 11px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $BACKGROUND;

  button {
    padding: 0;
  }

  span {
    text-decoration: underline;
    font-size: font-size(-1);
    font-weight: $SEMI_BOLD;
  }

  svg {
    height: 24px;
    width: 24px;
    vertical-align: middle;
  }
}

.mobileFilterMainPage {
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 20px; // this is the same as the amount of padding in the modal backdrop
  width: calc(100% - 40px); // 40px for padding left and right of 20px on modal backdrop
  height: calc(100% - 40px);
  z-index: $SKIP_BUTTON_Z_INDEX + 2;
  background-color: $WHITE;

  .filterPopupContent {
    padding: 24px 0;

    .filterMenuContainer {
      padding: 0;

      li {
        display: list-item;
        margin: 0;
        width: 100%;
        $CONTENT_AREA_PADDING_TOP_BOTTOM: 11px;

        border-left: $ACCORDION_SIDE_BORDER_WIDTH solid $BACKGROUND;
        font-weight: $MEDIUM;

        &.disabledListItem {
          &:hover {
            border-color: $BACKGROUND;
          }
        }

        &:hover {
          border-color: $PRIMARY;
        }

        &:before {
          display: none;
        }

        button {
          width: 100%;
          text-align: left;
          padding: $CONTENT_AREA_PADDING_TOP_BOTTOM 16px $CONTENT_AREA_PADDING_TOP_BOTTOM calc(16px - #{$ACCORDION_SIDE_BORDER_WIDTH});
          text-decoration: none;
          display: inline-flex;

          span {
            margin: 0 !important;
            margin-right: 10px !important;

            &:last-child {
              margin-right: 0 !important;
              margin-left: auto !important;
              display: flex;
              align-self: center;
            }
          }

          .filterGroupTitle {
            margin-right: 10px;
          }

          svg {
            height: 16.4px;
            width: 16.4px;
            vertical-align: middle;
            transform: rotate(90deg);
            color: $PRIMARY;

            &.disabledIcon {
              color: $GREY_05;
            }
          }

          &:hover {
            color: $BLACK !important;
            text-decoration: underline;
            background-color: $BACKGROUND;
          }

          &:disabled {
            background-color: $GREY_02;
            color: $GREY_05 !important;
            text-decoration: none;
          }
        }
      }
    }

    .filterButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      padding: 0 16px;
      padding-left: 6px;

      button {
        font-size: font-size(0);
      }
    }
  }
}

.openedFilterPopup {
  position: fixed;
  top: 20px; // this is the same as the amount of padding in the modal backdrop
  width: calc(100% - 40px); // 40px for padding left and right of 20px on modal backdrop
  height: calc(100% - 40px);
  z-index: $SKIP_BUTTON_Z_INDEX + 3;
  background-color: $WHITE;

  .openedFilterContent {
    margin-top: 24px;

    .openedFilterHeading {
      padding: 9px 16px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      button {
        margin-right: 22px;

        svg {
          width: 16.4px;
          height: 16.4px;
          vertical-align: middle;
          transform: rotate(270deg);
          color: $PRIMARY;
        }
      }

      span {
        margin: 0;
      }
    }

    .openedFilterChildren {
      max-height: calc(100vh - 200px); // minused totals of padding, header and margin
    }
  }
}

// Animations

.slideUp {
  position: fixed;
  animation: slideUp 0.35s ease-out;

  @keyframes slideUp {
    0% {
      top: 100vh;
    }
    100% {
      top: 0;
    }
  }
}

.slideDown {
  position: fixed;
  animation: slideDown 0.35s ease-out;

  @keyframes slideDown {
    0% {
      top: 0;
    }
    100% {
      top: 100vh;
    }
  }
}

.slideLeft {
  position: fixed;
  animation: slideLeft 0.5s ease-out;

  @keyframes slideLeft {
    0% {
      left: 100vw;
    }
    100% {
      left: 0;
    }
  }
}

.slideRight {
  position: fixed;
  animation: slideRight 0.5s ease-out;

  @keyframes slideRight {
    0% {
      left: 0;
    }
    100% {
      left: 100vw;
    }
  }
}

.fadeIn {
  @keyframes fade-in {
    0% {
      opacity: 0.2;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.6;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 0.8s ease-in;
}

.fadeOut {
  @keyframes fade-out {
    0% {
      opacity: 0.8;
    }
    25% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
  animation: fade-out 0.8s ease-in;
}
