/*
  DataExplorer.module.scss

  This file contains all scoped style for DataExplorer.jsx.
*/

@import '../../Styles/global';

.bold {
  font-weight: $BOLD;
}

.dataPageLinks {
  display: flex;
  gap: 8px;
  margin-top: 16px;

  @include mobile {
    flex-direction: column;
  }

  a {
    display: flex;
    align-items: center;
    width: fit-content;
    svg {
      height: 24px;
      width: 24px;
      margin-left: 8px;
      padding: 4px;
    }
  }
}

.dataSetsContainer {
  padding-bottom:24px;
  .entityTypes {
    display: flex;
    gap: 24px;

    @include mobile {
      flex-direction: column;
    }

    span {
      display: flex;
      flex-wrap: wrap;

      a {
        color: $BLACK;
        display: flex;
        align-items: center;
        margin-left: 8px;

        svg {
          height: 16px;
          width: 16px;
          margin-left: 4px;
        }
      }
    }
  }
}

.titleArea {
  padding: 24px 24px 0 24px;
}

.messageBox {
  margin-top: 24px;
}

.failedMessage {
  text-align: center;
  margin-top: 40px;
}
form.sectionContainer{
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.filterList {

  .upperBlockContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    gap: 0 24px;
    width:100%;
    padding: 24px;
  }

  .filterCardContainer{
    padding: 16px 0px;
    border-bottom: 1px solid #E1ECEB;
    .filterContainer {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding-bottom: 12px;
      align-items: center;
  
      &>div{
        width:80%;
      }
    
      .filterTitle {
        font-weight: $MEDIUM;
    
        &.isRequired {
          &:after {
            content: ' *';
            display: inline;
            color: $ERROR;
          }
        }
      }
    
      .titleArea {
        padding: 24px 24px 0 24px;
        font-size: font-size(-1);
        margin-bottom: 16px;
      }
    
      .listOptions {
        padding: 0 16px;
    
        li {
          padding: 0;
        }
    
        label {
          font-size: font-size(0);
          font-weight: $REGULAR;
          padding: 16px 0;
        }
      }
    
      .dataSetFilter {
        padding: 24px;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        column-gap: 24px;
    
        @include tablet {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
    
        @include mobile {
          padding: 0;
        }
    
        .groupedDataSets {
          background-color: $GREEN_01;
          padding: 24px;
          height: fit-content;
          border-radius: 8px;
    
          @include tablet {
            padding: 16px 12px;
          }
    
          @include mobile {
            border-radius: 0;
          }
    
          .groupedList {
            margin-top: 10px;
    
            .groupedContainerList {
              background-color: $GREEN_01;
              border-top: 2px solid $GREEN_02;
              border-bottom: 2px solid $GREEN_02;
              border-radius: 0;
              max-height: 256px;
              overflow-y: auto;
    
              @include mobile {
                max-height: 240px;
              }
    
              li {
                label {
                  padding-left: 0;
                  padding-right: 0;
                }
              }
            }
    
            .groupedListItem {
              grid-template-columns: auto auto;
              column-gap: 8px;
            }
          }
        }
    
        .dataSetList {
          @include mobile {
            padding: 0 12px 24px 12px;
          }
          .checkboxLabel {
            margin-top: 16px;
            display: grid;
            grid-template-columns: auto 1fr;
            padding-bottom: 16px;
          }
    
          .dataSetFiltersList {
            max-height: 378px;
            overflow-y: auto;
            display: flex;
    
            .groupedBorder {
              @include tablet {
                width: 100%;
              }
    
              @include mobile {
                border-radius: 4px;
                border: 2px solid $GREEN_02;
                box-shadow: 0px 4px 18px -12px rgba(0, 0, 0, 0.2) inset;
                max-height: 356px;
              }
            }
          }
        }
      }
      .listSelectSelectAll {
        background-color: $WHITE;
        padding: 0 16px;
    
        .selectAllCheckbox {
          padding: 16px 0;
          display: flex;
          border-bottom: 2px solid $PRIMARY;
          font-size: font-size(0);
          font-weight: $REGULAR;
        }
      }
    
      .reportingYearFilterContainer {
        padding: 24px 24px 0 24px;
    
        &.bottomMargin {
          margin-bottom: 32px !important;
        }
    
        @include mobile {
          padding: 24px 12px;
        }
    
        .reportingYearDropdown {
          max-width: 632px;
    
          .clearButton {
            margin-top: 10px;
          }
        }
      }
    
      .entityFilterContainer {
        margin-top: 32px;
        padding: 32px 24px 24px 24px;
        border-top: 1px solid $PRIMARY;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        column-gap: 24px;
    
        @include tablet {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
    
        @include mobile {
          padding: 0;
        }
    
        .bodyTypePortfolioContainer {
          display: flex;
          flex-direction: column;
          gap: 24px;
          padding: 24px;
          background-color: $GREEN_01;
          height: fit-content;
          border-radius: 8px;
    
          @include mobile {
            padding: 12px;
            border-radius: 0;
          }
        }
    
        .entityOptionsContainer {
          @include mobile {
            padding: 12px 12px 24px 12px;
          }
    
          .fullWidth {
            max-width: 100%;
            margin-bottom: 0 !important;
    
            & > div {
              & > div {
                padding: 0;
              }
            }
          }
    
          .groupedListStyle {
            @include mobile {
              max-height: 356px;
              border-radius: 4px;
              border: 2px solid $GREEN_02;
              box-shadow: 0px 4px 18px -12px rgba(0, 0, 0, 0.2) inset;
              max-height: 356px;
            }
          }
        }
      }
    
      .entitySelectionControlButtons {
        padding: 0 24px 24px 24px;
        display: flex;
        justify-content: space-between;
    
        .disabledAbolishButton {
          background-color: $GREY_02 !important;
          cursor: not-allowed !important;
          text-decoration: line-through !important;
          color: $GREY_04 !important;
          border-color: transparent !important;
    
          &:hover,
          &:focus {
            background-color: $GREY_02 !important;
            cursor: not-allowed !important;
            text-decoration: line-through !important;
            color: $GREY_04 !important;
            border-color: transparent !important;
          }
        }
      }
    
      .additionalFiltersContainer {
        padding: 24px;
    
        @include mobile {
          padding: 24px 12px 12px 12px;
        }
    
        .additionalFilters {
          margin-top: 24px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 24px;
    
          @include mobile {
            display: flex;
            flex-direction: column;
          }
        }
      }
    
      .applyFilters {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        @include mobile {
          flex-direction: column;
          gap: 8px;
          align-items: flex-start;
        }
    
        p {
          margin: 0;
        }
    
        .groupedButtons {
          display: flex;
          flex-direction: column;
          gap: 16px;
          @include mobile {
            align-self: flex-end;
          }
    
          .filterButtons {
            display: flex;
            gap: 16px;
          }
        }
      }
    }
  }

  .lowerBlockContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    gap: 0 24px;
    width:100%;
    border-top: 1px solid #387b85;
    padding: 24px;
  }

  .singleFilterContainer{
    display: grid;
    grid-template-columns: 30%;
    gap: 0 24px;
    width:100%;
    padding: 24px;
  }




}


.filterButtons {
  display: flex;
  gap: 12px;
}


.exportAllCSV {
  width: fit-content;
}

.dataSetTableContainer {
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 2px solid $GREEN_02;

  @include mobile {
    padding: 40px 0;
  }

  h2 {
    margin: 0;
  }

  .filterAndTableContainer {
    display: flex;
    gap: 24px;
    flex-direction: column;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    

    .dataSetInfo {
      display: flex;
      flex-direction: row;
      gap: 8px;

      .specificAdditionalFilters,
      .annualReportLinksContainer
      {
        position: absolute;
        z-index: 5;
        width: 392px;
        overflow: visible;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
      }

      .annualReportLinksContainer a{
        border: 0;
        border-bottom: 1px;
        width: 100%;
      }

      @include mobile {
        border-bottom: none;
        padding-bottom: 0;
      }

      .aboutThisData {
        display: inline-block;
        max-height: 256px;
        overflow-y: auto;

        ul {
          padding: 0;
        }


      }

      .individualFilterButton {
        display: flex;
        width: 392px;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        border: 1px solid #387B85;
        background: #005562;
        justify-content: space-between;
        span {
          color: var(--White, var(--Colour-Text-Inverted, #FFF));
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 157.143% */

          &.required {
            &:after {
              content: '*';
              display: inline;
              color: $ERROR;
            }
          }

        }

        &[aria-expanded='true'] {
          border-radius: 8px 8px 0 0;
        }
      }

      .specificAdditionalFilters {
        padding: 16px;
        background-color: $BACKGROUND;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .specificFilterButtons {
        display: flex;
        flex-direction: row;
        gap: 8px;

        button {
          width: fit-content;
        }

        a {
          display: none;
        }

        @include mobile {
          flex-direction: row;
          justify-content: space-between;
        }
      }

      .annualReportLink {
        border-radius: 0px 0px var(--Value-Unit12, 2px) var(--Value-Unit12, 2px);
        background: #E1ECEB;
        display: flex;
        padding: 19px;
        align-items: flex-start;
        align-self: stretch;
        flex-direction: column;
        width: 390px;

        &:not(:last-child) {
          border-bottom: 1px solid $PRIMARY;
        }

        span {
          display: inline-block;
        }
      }

      .expandCellsButton {
        width: fit-content;
      }
    }
  }

  .annotationContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: $GREY_05;

    h3 {
      margin: 0;

      @include mobile {
        border-top: 1px solid $GREY_05;
        width: fit-content;
        padding-top: 16px;
      }
    }

    .annotation {
      display: grid;
      grid-template-columns: 288px 1fr;
      gap: 24px;

      @include mobile {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}

.footnoteData {
  max-width: 808px;
  padding-top: 48px;
  color: $GREY_05;
}

.loadingDataTables {
  margin-top: 20px;
  min-height: 400px;
}

.entitiesWrapper {
  .entityItem {
    display: flex;
    padding: 8px 0px;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #878787;


    .titleWrapper {
      min-width: 248px;
      max-width: 392px;
      flex: 1 0 0;
    }

    .listWrapper {
      flex: 1 0 0;
    }
  }
}

.controlWrapper {
  display: grid;
  gap: 8px;
  margin-bottom: 24px;

  button,
  [class*='btn'] {
    max-width: fit-content;
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      aspect-ratio: 1;
      margin-left: 8px;

      &.mirrored {
        transform: scaleY(-1);
      }

      &.newTab {
        height: 19px;
      }
    }
  }

  .label {
    font-size: 20px;
    font-weight: $MEDIUM;
    padding-top:24px
  }

  .buttonWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 8px;

    & > div {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    @include mobile {
      flex-direction: column;

      & > div {
        flex-direction: column;
      }
    }
  }
}

.graphView {
  margin: 32px 0 64px;

  @include mobile {
    margin-bottom: 24px;
  }



  .headingWrapper {
    display: flex;
    justify-content: space-between;
    row-gap: 38px;
    margin-bottom: 16px;

    @include mobile {
      flex-direction: column;
      row-gap: 16px;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .filter {
      position: relative;
      max-width: 290px;
      max-height: 54px;
      width: 100%;

      .filterAccordionButton {
        padding: 16px;
        font-size: font-size(-1);
        width: 100%;
        max-height: 54px;
      }

      .filterWrapper {
        background-color: $BACKGROUND;
        padding: 16px;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        border: 1px solid $GREEN_04;
        border-top: 0;
        border-radius: 0 0 4px 4px;
        z-index: 1;
      }
    }
  }

  .chartWrapper {
    display: flex;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid $GREY_03;
    background-color: $WHITE;
    padding: 16px;

    &.noData {
      background-color: $GREY_01;
      height: 480px;
      margin-top: 48px;
      background-image: var(--BACKGROUND-IMAGE);
      background-repeat: no-repeat;
      background-position: center;
    }

    @include mobile {
      border-radius: revert;
      padding: 17px 0;
      border: 0;

      &.noData {
        height: 400px;
        margin-top: 0;
      }
    }

    .chartGroup {
      display: grid;

      .charts {
        display: flex;

        .borders:not(:last-child) {
          border-right: 1px solid $GREEN_04;
          padding-right: 24px;
        }
      }

      .gropuHeading {
        padding: 8px;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:not(:first-child) {
        .charts,
        .gropuHeading {
          padding-left: 24px;
        }
      }

      &:not(:last-child) {
        .charts {
          border-right: 1px solid $GREEN_04;
          padding-right: 24px;
        }

        .gropuHeading {
          border-right: 1px solid $GREEN_04;
          padding-right: 32px;
        }
      }

      &:nth-child(odd) {
        .gropuHeading {
          background-color: $GREEN_01;
        }
      }
    }
  }

  .mobileSeperater {
    @include mobile {
      border-top: 1px solid $GREY_03;
      border-bottom: 1px solid $GREY_03;
      transform: translateX(-$MIN_CONTENT_SIDE_PADDING);
      width: 100svw;
    }
  }
}