/*
  ProfilePageLoading.module.scss

  This file contains all scoped style for ProfilePageLoading.jsx.
*/

@import '../../Styles/global';

.loadingBanner {
    min-height: 240px;
    margin-bottom: 48px;
  
    @include mobile {
      margin-bottom: 40px;
    }
}

#bottomHalf {
  display: grid;
  grid-template-columns: 1fr 20%;
  column-gap: 10.4575%;
  
  @include mobile {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .headingStyle {
      display: flex;
      gap: 16px;
      flex-direction: column;
      max-width: 100%;
  }

  .loadingItems {
    min-height: 200px;
    max-width: 100%;
    
    @include mobile {
      display: none;
    }
    
    @include tablet {
      display: none;
    }
  }

  .loadingHistory {
    width: 100%;
    grid-column: 2;
  }
}

.invisibleTitle {
  opacity: 0.001;
}

#profilesLoading {
  .profilesList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 32px;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    .cardTile {
      min-height: 391px;

      @include mobile {
        min-height: 172px;
      }
    }
  }
}
