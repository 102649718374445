/*
  LogosHeaderFooter.module.scss

  This file contains all scoped style for LogosHeaderFooter.jsx.
*/

@import '../../Styles/global';

.logoWrapper {
  --logo-wrapper-height: 48px;
  height: var(--logo-wrapper-height);
  display: flex;
  align-items: center;
  margin-right: auto;

  &:before {
    display: block;
    content: '';
    width: 1px;
    height: var(--logo-wrapper-height);
    background-color: $GREY_03;
    margin: 0 16px;
    order: 1;
  }

  .logo {
    &.ausGov {
      width: 92.28px;
      height: var(--logo-wrapper-height);
      order: 0;
    }

    &.transparency {
      width: 152.71px;
      height: 40px;
      order: 2;
    }
  }

  @include tablet {
    --logo-wrapper-height: 36px;

    &:before {
      margin: 0 12px;
    }

    .logo {
      &.ausGov {
        width: 70px;
      }
      &.transparency {
        width: 106.9px;
        height: 28px;
      }
    }
  }
}
