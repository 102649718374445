/*
  StandardFilterList.module.scss

  This file contains all scoped style for StandardFilterList.jsx.
*/
@import '../../../Styles/global';

// used to limit dropdown height of filters
.standardFilterList {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  margin: 16px 0 !important;

  @include mobile {
    width: 100%;
    max-height: calc(100vh - 175px);
  }
}
