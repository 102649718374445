/*
  PreviewWordControl.module.scss

  This file contains all scoped style for PreviewWordControl.jsx.
*/

@import '../../Styles/global';
.accordionExportButtonWrapper{
  padding:4px;

  #previewARcontrolPDF {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: space-between;
    margin-bottom: 8px;
  
    button {
      display: inline-flex;
      justify-content: center;
      gap: 8px;
      padding: 10px 4px;
    }

    ol {
      counter-reset: list;
    }
    ol > li {
        list-style: none;
    }
    ol > li:before {
        padding-right: 10px;
        content: counter(list) ") ";
        counter-increment: list;
    }
  }
}

