/*
  RelevantLinks.module.scss

  This file contains all scoped style for RelevantLinks.jsx.
*/

@import '../../Styles/global';

#RelevantLinks {
  position: relative;
  padding: $MAIN_PADDING_BOTTOM 0 $MAIN_PADDING_BOTTOM;
  margin-top: $MAIN_PADDING_BOTTOM;
  margin-bottom: -$MAIN_PADDING_BOTTOM;

  // background
  &:before {
    background-color: $GREY_01;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 24px;
  }

  ul {
    display: grid;
    padding: 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @include mobile {
      grid-template-columns: 1fr;
      gap: 16px;
    }

    & > li {
      display: grid;
      &:before {
        display: none;
      }

      .relevantLink {
        font-size: font-size(-1);
        font-weight: $REGULAR;
        padding-bottom: 44px;
        gap: 8px;

        .tags {
          display: flex;
          margin-bottom: 11px;
          gap: 8px;
        }

        .linkName {
          font-weight: $SEMI_BOLD;
          text-decoration: underline;
        }

        .extraText {
          text-decoration: underline;
        }
      }
    }
  }
}
