/*
  MainFooter.module.scss

  This file contains all scoped style for MainFooter.jsx.
*/

@import '../../Styles/global';

#footer {
  background-color: $PRIMARY;
  padding: 24px $HEADER_FOOTER_GUTTER 64px;

  .limiter {
    padding: 0;
    display: grid;
    gap: 48px;
    justify-content: space-between;
    grid-template-columns: 282px minmax(auto, 809px);
    max-width: $MAX_HEADER_FOOTER_CONTENT_WIDTH;
    margin: auto;
  }

  @include tablet {
    padding-left: $HEADER_FOOTER_GUTTER_MOBILE;
    padding-right: $HEADER_FOOTER_GUTTER_MOBILE;
  }

  @include mobile {
    .limiter {
      display: flex;
      flex-direction: column;
    }
  }

  * {
    color: $WHITE !important;
  }

  .logoWrapper {
    display: flex;
  }

  .contents {
    font-size: font-size(-1);

    menu {
      display: flex;
      padding-left: 0;
      gap: 8px;
      justify-content: space-between;
      margin-bottom: 0;
      flex-wrap: wrap;
      list-style-type: none;
      margin-top: 32px;

      &:first-child {
        margin-top: 0;
      }

      @include tablet {
        justify-content: initial;
      }

      @include mobile {
        flex-direction: column;
        font-size: font-size(0);
      }

      li {
        &:before {
          display: none;
        }
      }
    }
  }
}
