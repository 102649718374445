/*
  DataSetsFilterAccordion.module.scss

  This file contains all scoped style for DatasSetsFilterAccordion.jsx.
*/

@import '../../Styles/global';

.accordion {
  --arrow-color: #{$WHITE};

  // drop down accordion triangle
  .triangle {
    position: relative;
    color: $WHITE;

    svg {
      width: 24px;
      height: 24px;
      color: inherit;
    }

    &[aria-expanded='true'] {
      svg {
        transform: scale(-1);
      }
    }
  }

  .accordionButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 56px;
    align-items: center;
    padding: 15px 10px 15px 24px;
    position: relative;
    text-align: left;
    font-weight: $SEMI_BOLD;
    font-size: font-size(1);

    @include mobile {
      padding-left: 12px;
    }

    span {
      &.required {
        &:after {
          content: '*';
          display: inline;
          color: $ERROR;
        }
      }
    }

    &.isOpen {
      border-radius: 4px 4px 0 0;
      border-bottom: none;
    }
  }

  .accordionDropdown {
    transition: all $TRANSITION_STYLE $TRANSITION_TIMING;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
    overflow: hidden;
    display: none;

    &.contentShow {
      display: block;
      max-height: none;
      border: 1px solid $GREEN_04;
      border-top: none;
      border-radius: 0 0 4px 4px;
    }
  }
}
