/*
  Breadcrumb.module.scss

  This file contains all scoped style for Breadcrumb.jsx.
*/

@import '../../Styles/global';

#breadcrumbs {
  margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;
  margin-top: $CONTENT_ELEMENT_MARGIN_BOTTOM;

  ol {
    --CRUMB_DISTANCE: 8px;
    display: inline;
    padding-left: 0;

    li {
      display: inline;

      &:before {
        display: none;
      }

      &:after {
        display: inline;
        content: '/';
        margin: 0 var(--CRUMB_DISTANCE);
      }

      &:last-child:after {
        display: none;
      }

      > * {
        display: inline;
        color: $BLACK;
        font-size: font-size(-2);

        &[aria-current='page'] {
          text-decoration: none;
        }
      }
    }
  }

  .loadingCrumb {
    width: 50px;
    height: 100%;
    display: inline-block;
  }

  @include mobile {
    margin-bottom: 8px;

    ol {
      --CRUMB_DISTANCE: 0.3rem;
    }
  }
}
