/*
  History.module.scss

  This file contains all scoped style for History.jsx.
*/

@import '../../Styles/global';

#historyHolder {
  padding: $MAIN_PADDING_BOTTOM 0 0;

  @include mobile {
    padding-top: 32px;
  }

  padding-bottom: 24px;
  position: relative;

  & > h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  #historyLine {
    position: relative;
    padding-left: 0;
    margin-bottom: 4px;
    overflow: hidden;

    & > li {
      margin-bottom: 24px;
      gap: 16px;
      display: grid;
      grid-template-columns: 16px 1fr;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      // list dot
      &:before {
        box-sizing: border-box;
        display: block;
        content: '';
        width: 16px;
        height: 16px;
        padding-right: 0;
        border-radius: 50%;
        background-color: $PRIMARY;
        transform-origin: center;
        transform: translate(4px, 2px);
      }

      &:not(:last-child) {
        &:after {
          content: '';
          display: block;
          box-sizing: border-box;
          height: calc(100% + 24px);
          width: 8px;
          background-color: $BACKGROUND;
          position: absolute;
          left: 0;
          z-index: -1;
          transform-origin: center;
          transform: translate(8px, 5px);
        }
      }

      strong,
      span {
        display: block;
      }

      strong {
        font-weight: $SEMI_BOLD;
      }

      &:empty {
        &:before {
          display: none;
        }

        &:after {
          height: 500px;
        }
      }
    }
  }

  .tailConnector {
    content: '';
    display: block;
    box-sizing: border-box;
    height: 40%;
    width: 8px;
    background-color: $BACKGROUND;
    position: absolute;
    left: 0;
    z-index: -1;
    border-radius: 8px;
    transform-origin: center;
    transform: translate(8px, calc(-100% - 5px));
  }

  // three dots at the end
  .tail {
    display: block;
    content: '';
    background-color: $BACKGROUND;
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 8px;
    transform: translateX(8px);

    &:before,
    &:after {
      display: block;
      content: '';
      width: inherit;
      height: inherit;
      background-color: inherit;
      border-radius: inherit;
      position: absolute;
    }

    &:before {
      top: calc(100% + 4px);
    }

    &:after {
      top: calc(200% + 8px);
    }
  }

  // show more button
  button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
