/*
  DataSetsHeader.module.scss

  This file contains all scoped style for DataSetsHeader.jsx.
*/

@import '../../Styles/global';

.bold {
  font-weight: $BOLD;
}

.dataPageLinks {
  display: flex;
  gap: 8px;
  margin-top: 16px;

  @include mobile {
    flex-direction: column;
  }

  a {
    display: flex;
    align-items: center;
    width: fit-content;
    svg {
      height: 24px;
      width: 24px;
      margin-left: 8px;
      padding: 4px;
    }
  }
}

.dataSetsContainer {
  .entityTypes {
    display: flex;
    gap: 24px;

    @include mobile {
      flex-direction: column;
    }

    span {
      display: flex;
      flex-wrap: wrap;

      a {
        color: $BLACK;
        display: flex;
        align-items: center;
        margin-left: 8px;

        svg {
          height: 16px;
          width: 16px;
          margin-left: 4px;
        }
      }
    }
  }
}
