/*
  _mixins.scss

  This file contains all the sass/scss mixins functions
*/

/*
===== TABLE OF CONTENT =====
1. RESPONSIVENESS
2. MATH FUNCTIONS
3. COLOUR FUNCTIONS
4. UTILITIES
*/

@use 'sass:math';

/*==============================================================
1. RESPONSIVENESS
==============================================================*/
@mixin desktop {
  @media screen and (min-width: $DESKTOP_MIN) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $TABLET_MAX) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $MOBILE_MAX) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

/*==============================================================
2. MATH FUNCTIONS
==============================================================*/

// SUMMATION
@function summation($iteratee, $input, $initial: 0, $limit: 100) {
  $sum: 0;

  @for $index from $initial to $limit {
    $sum: $sum + call(get-function($iteratee), $input, $index);
  }

  @return $sum;
}

// FACTORIAL
@function factorial($value) {
  $result: 1;

  @if $value==0 {
    @return $result;
  }

  @for $index from 1 through $value {
    $result: $result * $index;
  }

  @return $result;
}

// EXP-MACLAURIN
@function exp-maclaurin($x, $n) {
  @return math.div(pow($x, $n), factorial($n));
}

// EXP
@function exp($value) {
  @return summation('exp-maclaurin', $value, 0, 100);
}

// LN-MACLAURIN
@function ln-maclaurin($x, $n) {
  @return math.div(pow(-1, $n + 1), $n) * (pow($x - 1, $n));
}

// LN
@function ln($value) {
  $ten-exp: 1;
  $ln-ten: 2.30258509;

  @while ($value > pow(10, $ten-exp)) {
    $ten-exp: $ten-exp + 1;
  }

  @return summation(ln-maclaurin, math.div($value, pow(10, $ten-exp)), 1, 100) + $ten-exp * $ln-ten;
}

// POW
@function pow($number, $exponent) {
  @if (round($exponent) !=$exponent) {
    @return exp($exponent * ln($number));
  }

  $value: 1;

  @if $exponent>0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  } @else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $value: math.div($value, $number);
    }
  }

  @return $value;
}

/*==============================================================
3. COLOUR FUNCTIONS
==============================================================*/

// MIXED A BASE COLOUR WITH BLACK
@function shade($base, $amount) {
  @return mix($base, #000, $amount);
}

// MIXES A BASE COLOUR WITH WHITE
@function tint($base, $amount) {
  @return mix($base, #fff, $amount);
}

/*==============================================================
4. UTILITIES
==============================================================*/

// STRIP OUT UNIT (eg. 16px -> 16)
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
    // @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// FONT SIZE
@function font-size($multiple, $scale: $BASE_TYPE_SCALE) {
  $val: 1 * pow($scale, $multiple);
  @return #{$val}rem;
}

// PADDING / MARGIN SIZE
/**
  If you don't pass in scale, it defaults to 10px.
  If you pass in scale, it'll use the one passed in.
*/
@function spacer($multiple, $scale: 10) {
  $val: 1 * $scale * $multiple;
  @return #{$val}px;
}

// CONVERT PX TO REM (eg. if base is 16px, rem(16px) or rem(16) -> 1rem)
@function rem($px, $base: $BASE_TYPE_SIZE) {
  @return #{math.div(strip-unit($px), strip-unit($base))}rem;
}

// ASPECT RATIO
@mixin aspect($x, $y) {
  @extend %aspect;
  &::before {
    padding-top: #{($y / $x) * 100}#{'%'};
  }
}

%aspect {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
  }

  > * {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @supports (object-fit: cover) {
    > img,
    > svg,
    > video {
      object-fit: cover;
      object-position: center center;
      height: 100%;
      width: 100%;
    }
  }
}
