/*
  FilterLoading.module.scss

  This file contains all scoped style for FilterLoading.jsx.
*/

@import '../../Styles/global';

.filter {
  display: grid;
  grid-template-columns: 3fr 1fr;
  row-gap: font-size(0);
  padding-bottom: 16px;
  border-bottom: 2px solid #a9c6c9;
  padding: 32px 0;

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  .filterDropdownButtons {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    li {
      height: 44px;
      width: 150px;
    }
  }

  .filterButtons {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-self: end;

    div {
      width: 60px;
      height: 40px;
    }
  }

  .selectedFilters {
    min-height: 25px;
  }

  .mobileFilter {
    height: 44px;
  }

  .numberOfResults {
    width: 160px;
    margin-left: auto;

    @include mobile {
      width: 100%;
      height: 24px;
    }
  }
}
