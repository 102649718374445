/*
  FinancialRatios.module.scss

  This file contains all scoped style for FinancialRatios.jsx.
*/

@import '../../Styles/global';

.formField {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .fieldTitle {
    font-weight: $MEDIUM;
  }
}

.messageHolder {
  margin-top: 16px;
}

.financialRatioTables {
  position: relative;

  .selectFields {
    display: grid;
    grid-template-columns: 312px 1fr;

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    .selectText {
      padding: 32px 16px;
      border-right: 1px solid $GREY_03;

      & > span {
        font-weight: $MEDIUM;
      }

      @include mobile {
        border-right: none;
        border-bottom: 1px solid $GREY_03;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .dropdownOptions {
      padding: 32px 12px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .applyButton {
        width: fit-content;
        align-self: flex-end;
      }

      @include mobile {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .tables {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include mobile {
      position: relative;
      right: $MIN_CONTENT_SIDE_PADDING;
      width: calc(100% + ($MIN_CONTENT_SIDE_PADDING * 2));
    }

    table {
      margin-bottom: 0 !important;

      th,
      td {
        padding: 10px 12px;
      }

      caption div {
        font-size: font-size(5);
        text-align: left;
        background-color: $GREEN_01;
        border-bottom: 1px solid $GREEN_03;
        padding: 10px;
        font-weight: $BOLD;

        a {
          width: 44px;
          height: 44px;
          vertical-align: middle;
          margin-left: 8px;
          display: inline-flex;
          align-items: center;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        @include mobile {
          display: grid;
          grid-template-columns: 1fr 44px;
          width: 100%;
          align-items: center;
          padding-left: $MIN_CONTENT_SIDE_PADDING;
          padding-right: $MIN_CONTENT_SIDE_PADDING;
        }
      }

      tbody {
        tr {
          &:not(:last-child) {
            th,
            td {
              &:not(.blankedOut) {
                border-bottom: 1px solid $GREY_03;
              }
            }
          }

          &:last-child {
            th,
            td {
              &:not(.blankedOut) {
                border-top: 4px solid $PRIMARY;
                border-bottom: 4px solid $PRIMARY;
                font-weight: $BOLD;
              }
            }

            th {
              @include mobile {
                font-weight: $SEMI_BOLD !important;
              }
            }
          }

          th {
            width: 312px;
            text-align: left;
            border-right: 1px solid $GREY_03;

            @include mobile {
              width: 30%;
              box-shadow: 12px 0px 10px -2px rgba(0, 0, 0, 0.1);
              border-right: none;
              font-weight: $SEMI_BOLD;
              padding-left: $MIN_CONTENT_SIDE_PADDING;
            }
          }

          td {
            text-align: right;
            border-right: 1px solid $GREY_03;

            &.blankedOut {
              background-color: $GREY_01;
            }

            &:last-child {
              padding-right: $MIN_CONTENT_SIDE_PADDING;
            }
          }
        }
      }
    }
  }

  .selectedARView {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      gap: 24px;
    }

    .publicationCard {
      width: 288px;

      @include mobile {
        width: 100%;
      }
    }
  }
}

.financialRatioContent {
  margin-top: 48px;
  padding-top: 24px;
  border-top: 2px solid $GREEN_02;
  display: grid;
  grid-template-columns: 392px 1fr;
  gap: 24px;

  @include tablet {
    grid-template-columns: 1fr 2fr;
  }

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  .exportSelection {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .downloadButton {
      width: fit-content;
      display: flex;
      align-self: flex-end;

      svg,
      [class='spinner'] {
        height: 24px;
        width: 24px;
        margin-left: 8px;
      }
    }
  }

  .content {
    h2:first-child {
      margin-top: 0;
    }

    a {
      color: $BLACK !important;
      margin-left: 10px;
      font-size: font-size(1);
    }
  }
}

.fetchingOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(2px);
  text-align: center;

  .modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $WHITE;
    border: 1px solid $BLACK;
    border-radius: 4px;
    padding: 16px;
    position: sticky;
    top: 50%;
  }
}

.hidden {
  display: none;
}

.displayCoverImage {
  display: block;

  li {
    padding: 0;
  }
}
