/*
  DataExplorerTable.module.scss

  This file contains all scoped style for DataExplorerTable.jsx.
*/

@import '../../Styles/global';

.DataExplorerTable{
  border: 1px solid #868686;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  th, td {
    width: 10%; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 35px; 
    vertical-align: middle; 
    padding:4px;
  }

  .greyFill {
    border-bottom: 2px solid #515151;
    background: #515151;
    border: 1px solid #868686;
    
    p{
      color: var(--White, var(--Colour-Text-Inverted, #FFF));
    }
  }
  
  .countColumn {
    max-width: 3%;
    min-width: 3%;
    padding:4px 16px;
  }

  .darkFill{
    background: var(--Primary, #005562);
    border: 1px solid #868686;
  }

  .headingRow{
    .columnHeading{
      display: flex;
      padding: 8px 10px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }
  }

  .columnHeadingButton{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 16px;
    color:#ffffff;
    text-wrap: auto;
    &:focus,&:active{
      background-color: inherit;
      color: #ffffff !important;
    }
  }


  .valueCell {
    background: #FADB00;
    border: 1px solid #868686;
    padding:6px 8px;
  }

  .whiteFill{
    border: 1px solid #868686;
    padding:6px 8px;
  }

  .valueColumn{
    width:8%;
  }

}
