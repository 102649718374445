/*
  ContributerLandingPage.module.scss

  This file contains all scoped style for ContributerLandingPage.jsx.
*/

@import '../../Styles/global';

.dart {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @for $i from 1 through 4 {
    h#{$i} {
      margin-top: 0;
      margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;
    }
  }

  figure {
    margin: 0;

    img {
      border: 1px solid $GREY_04;
      width: 100%;
    }
  }

  .hero {
    padding: 98px 0;

    @include tablet {
      padding: 48px 0;
    }

    & > div {
      max-width: 808px;

      @include tablet {
        max-width: 100%;
      }

      h1 {
        padding: 0;
        border: 0;
      }

      .message {
        margin: $ARTICLE_CONTENT_MARGIN_BOTTOM_MOBILE 0 $CONTENT_ELEMENT_INNER_MARGIN;
      }

      a[class*='btn'] {
        position: relative;
        padding-right: 42px;

        svg {
          width: 16.4px;
          height: 16.4px;
          position: absolute;
          top: 50%;
          right: 13.8px;
          transform-origin: center;
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }

    &:after {
      display: block;
      content: '';
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      background-image: var(--BACKGROUND-IMAGE);
      background-repeat: no-repeat;
      background-size: auto 650px;
      background-position-x: 70vw;
      background-position-y: 100%;
    }
  }

  section {
    margin-bottom: 80px;

    &:first-of-type {
      margin-top: 60px;
    }

    &:last-of-type {
      margin-bottom: 60px;
    }

    @include tablet {
      margin-bottom: 60px;
    }

    .nestedSection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $CONTENT_ELEMENT_MARGIN_BOTTOM 24px;
      margin-bottom: 80px;

      @include mobile {
        display: flex;
        flex-direction: column;
      }
    }

    .limitedWidth {
      max-width: 496px;
    }
  }

  aside {
    padding: 40px;
    background-color: $GREEN_01;
    border-radius: calc($BORDER_RADIUS * 2);

    h3 {
      grid-column: 1/3;
      margin-bottom: 0;
    }

    ul > li {
      margin-bottom: 12px;
    }
  }

  .relatedLinks {
    padding: 48px 0 96px;

    @include tablet {
      padding-bottom: 48px;
    }

    h2 {
      margin-bottom: 24px;
    }

    &:before {
      background-color: $GREY_01;
    }

    ul {
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $CONTENT_ELEMENT_MARGIN_BOTTOM 24px;

      & > li:before {
        display: none;
      }

      [class='card'] {
        min-height: 166px;
        padding-bottom: 16px;
      }

      @include mobile {
        grid-template-columns: 1fr;
        gap: 24px;
      }
    }
  }
}
