/*
  PortfolioEntitiesCompaniesLoading.module.scss

  This file contains all scoped style for PortfolioEntitiesCompaniesLoading.jsx.
*/

@import '../../Styles/global';

.pecLoading {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  .loadingPortfolioCards {
    height: 180px;
  }
}
