/*
  LinkCopy.module.scss

  This file contains all scoped style for LinkCopy.jsx.
*/

@import '../../Styles/global';

.LinkCopyWrapper {
  position: absolute;
  top: 0;
  right: 100%;
  font-size: font-size(0);
  display: flex;

  button.LinkCopy {
    background-color: transparent !important;
    color: transparent;
    padding: 0;
    min-width: 24px;
    min-height: 24px;
    font-size: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(45deg);
    }

    &:focus,
    &:active {
      color: $BLACK !important;
    }

    &:hover {
      color: $LINK_HOVER !important;
    }
  }
}
