/*
  Chart.module.scss

  This file contains all scoped style for Chart.
*/

@import '../../Styles/global';

.chart {
  padding: 8px 0;
  ul,
  ol {
    li {
      &:before {
        display: none;
      }
    }
  }
}

.xAxisTickLabel {
  font-weight: $BOLD;
}

[class='recharts-cartesian-grid-horizontal'],
[class='recharts-cartesian-grid-vertical'] {
  line {
    stroke: $GREEN_02;
  }
}

[class='recharts-cartesian-axis-line'] {
  stroke: $GREEN_02;
}

.tooltipWrapper {
  background-color: $WHITE;
  padding: 8px;
  border: 1px solid $GREY_03;
  border-radius: 4px;

  .xAxisTickLabel {
    margin-bottom: 8px;
  }

  ol {
    padding-left: 0;

    li {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
      padding: 3px 0;

      .keyIndicator {
        display: flex;
        gap: 4px;
        align-items: center;

        &:before {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: var(--ITEM_COLOR);
        }
      }
    }
  }
}
