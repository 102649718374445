/*
  Tag.module.scss

  This file contains all scoped style for Tag.jsx.
*/

@import '../../Styles/global';

.fileDownload {
  padding: 16px 32px 16px 24px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin-bottom: 8px;

  .iconState {
    max-width: 24px;

    svg {
      margin-top: 4px;
      width: 24px;
      height: 24px;
    }
  }

  .fileInfo {
    display: flex;
    flex-direction: column;

    span {
      text-align: left;
      font-size: font-size(-1);
    }

    .fileName {
      font-weight: $SEMI_BOLD;
      text-decoration: underline;
    }
  }

  &.listItem {
    width: calc(50% - 4px);
    margin-bottom: 8px;
    display: inline-flex;

    &:nth-child(even of .listItem) {
      margin-left: 4px;
    }

    &:nth-child(odd of .listItem) {
      margin-right: 4px;
    }

    @include mobile {
      width: 100%;
      display: flex;

      &:nth-child(even of .listItem) {
        margin-left: 0;
        margin-right: 0;
      }

      &:nth-child(odd of .listItem) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
