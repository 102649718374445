/*
  MessageBox.module.scss

  This file contains all scoped style for MessageBox.jsx.
*/

@import '../../Styles/global';

.messageBox {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  background: #EFF5F5;

  @include mobile {
    padding: 8px;
  }

  &.confirmed {
    border-color: $ACCEPTED;
    background-color: $MINT;
  }

  &.information {
    border-color: $GREY_04;
    background-color: $GREY_01;

    h2 {
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; 
    }  
    .head {
      flex-direction: column;
    }
  }

  &.help {
    border-color: $LINK_HOVER;
    background-color: $BACKGROUND_BLUE;
  }

  &.warning {
    border: 1px solid var(--System-Error, #E50000);
    background: var(--System-Peach, #FFE6E6);
    .head {
      flex-direction: row;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    color: $BLACK;
  }

  .head {
    display:flex;
    padding: var(--Value-Unit50, 8px) 0px;
    align-items: flex-start;
    gap: var(--Value-Unit50, 8px);
    align-self: stretch;

    .title {
      margin-top: 0;
    }
  }

  .content {
    font-size: font-size(-1);
    width:100%;

    &.noTitle {
      padding-left: 0;
      display: grid;
      grid-template-columns: 24px 1fr;
      gap: 16px;
    }
  }
}

.buttonRow {
  button {
    padding-bottom: 14px;
  }
  svg {
    height: 24px;
    width: 24px;
    padding-top: 7%;
    padding-left: 2%;
  }
}