/*
  Carousel.module.scss

  This file contains all scoped style for Carousel.jsx.
*/

@import '../../Styles/global';

.carouselContainer {
  --CAROUSEL-PAGE: 1;
  --CAROUSEL-WIDTH: 0px;
  --CAROUSEL-HEIGHT: 0px;
  $ICON_HEIGHT: 24px;
  overflow: hidden;

  .carousel {
    display: flex;
    width: fit-content;
    transform: translateX(calc((-1 * var(--CAROUSEL-WIDTH)) * (var(--CAROUSEL-PAGE) - 1)));
    transition: transform 0.5s ease-in-out;
    min-height: calc(var(--CAROUSEL-HEIGHT) - 65px);

    .carouselItem {
      width: calc(var(--CAROUSEL-WIDTH));
      border-left: 1px solid $PRIMARY;
      border-right: 1px solid $PRIMARY;
      overflow-y: auto;
      max-height: calc(var(--CAROUSEL-HEIGHT) - 65px); // minus 65px for extra carouselControls height
    }
  }

  .carouselControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid $PRIMARY;
    background-color: $GREY_01;

    button {
      margin: 0 !important;
      font-size: 0;
      padding: 0;
      margin: $CONTENT_ELEMENT_MARGIN_BOTTOM;

      svg {
        height: $ICON_HEIGHT;
        width: $ICON_HEIGHT;
      }
    }

    .previousButton {
      height: 32px !important;
      width: 32px !important;
      svg {
        transform: rotate(270deg);
      }
    }

    .nextButton {
      height: 32px !important;
      width: 32px !important;
      svg {
        transform: rotate(90deg);
      }
    }

    .indicators {
      display: flex;
      gap: 28px;
      .circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid $PRIMARY;

        &.highlighted {
          background-color: $PRIMARY;
          border-color: $PRIMARY;

          &:hover {
            background-color: $PRIMARY !important;
          }
        }
      }
    }
  }
}
