/*
  GraphView.module.scss

  This file contains all scoped style for GraphView.jsx.
*/

@import '../../Styles/global';

.graphView {
  margin: 32px 0 64px;

  @include mobile {
    margin-bottom: 24px;
  }

  .controlWrapper {
    display: grid;
    gap: 8px;
    margin-bottom: 24px;

    button,
    [class*='btn'] {
      max-width: fit-content;
      display: flex;
      align-items: center;

      svg {
        height: 16px;
        aspect-ratio: 1;
        margin-left: 8px;

        &.mirrored {
          transform: scaleY(-1);
        }

        &.newTab {
          height: 19px;
        }
      }
    }

    .label {
      font-size: font-size(-1);
      font-weight: $MEDIUM;
    }

    .buttonWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 8px;

      & > div {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }

      @include mobile {
        flex-direction: column;

        & > div {
          flex-direction: column;
        }
      }
    }
  }

  .headingWrapper {
    display: flex;
    justify-content: space-between;
    row-gap: 38px;
    margin-bottom: 16px;

    @include mobile {
      flex-direction: column;
      row-gap: 16px;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .filter {
      position: relative;
      max-width: 290px;
      max-height: 54px;
      width: 100%;

      .filterAccordionButton {
        padding: 16px;
        font-size: font-size(-1);
        width: 100%;
        max-height: 54px;
      }

      .filterWrapper {
        background-color: $BACKGROUND;
        padding: 16px;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        border: 1px solid $GREEN_04;
        border-top: 0;
        border-radius: 0 0 4px 4px;
        z-index: 1;
      }
    }
  }

  .chartWrapper {
    display: flex;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid $GREY_03;
    background-color: $WHITE;
    padding: 16px;

    &.noData {
      background-color: $GREY_01;
      height: 480px;
      margin-top: 48px;
      background-image: var(--BACKGROUND-IMAGE);
      background-repeat: no-repeat;
      background-position: center;
    }

    @include mobile {
      border-radius: revert;
      padding: 17px 0;
      border: 0;

      &.noData {
        height: 400px;
        margin-top: 0;
      }
    }

    .chartGroup {
      display: grid;

      .charts {
        display: flex;

        .borders:not(:last-child) {
          border-right: 1px solid $GREEN_04;
          padding-right: 24px;
        }
      }

      .gropuHeading {
        padding: 8px;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:not(:first-child) {
        .charts,
        .gropuHeading {
          padding-left: 24px;
        }
      }

      &:not(:last-child) {
        .charts {
          border-right: 1px solid $GREEN_04;
          padding-right: 24px;
        }

        .gropuHeading {
          border-right: 1px solid $GREEN_04;
          padding-right: 32px;
        }
      }

      &:nth-child(odd) {
        .gropuHeading {
          background-color: $GREEN_01;
        }
      }
    }
  }

  .mobileSeperater {
    @include mobile {
      border-top: 1px solid $GREY_03;
      border-bottom: 1px solid $GREY_03;
      transform: translateX(-$MIN_CONTENT_SIDE_PADDING);
      width: 100svw;
    }
  }
}
