/*
  SearchBar.module.scss

  This file contains all scoped style for SearchBar.jsx.
*/

@import '../../Styles/global';

.searchBar {
  flex-grow: 1;
  display: flex;
  position: relative;
  padding: 0;
  border: 0;
  margin: 0;

  label {
    display: none;
  }

  button {
    font-size: 0;
    border-radius: 0 2px 2px 0;
    height: 100%;
    width: 44px;
    margin: 0 !important;

    svg {
      width: 24px;
      height: 24px;
    }

    &#clear {
      font-size: 0;
      border-radius: 0;
      position: absolute;
      right: 44px;
      z-index: 1;
      color: $GREY_04;
      height: calc(100% - 2px);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
