/*
  StandardPage.module.scss

  This file contains all style for StandardPage.jsx.
  All scoped style within page not found can be found here.
*/

@import '../../Styles/global';

#backToHome {
  color: $BLACK;
}

#standardPageContent {
  display: grid;
  grid-template-columns: 312px 1fr minmax(520px, 832px);
  flex-grow: 1;

  &.noBottomPadding {
    margin-bottom: -$MAIN_PADDING_BOTTOM;
  }

  & > div {
    padding: 48px 0;

    &#firstCol {
      padding-right: 24px;
      border-right: 2px solid $BACKGROUND;
    }

    &#lastCol {
      padding-left: 24px;
      max-width: 832px;
      grid-column: 3;
    }
  }

  @include tablet {
    display: flex;
    flex-direction: column;

    & > div {
      &#firstCol {
        display: none;
      }

      &#lastCol {
        padding: 32px 0 63px;
        padding-left: 0;
        max-width: 100%;
      }
    }
  }

  @include mobile {
    display: block;

    & > div {
      padding: 32px 0 47px;

      &#firstCol {
        display: none;
      }

      &#lastCol {
        padding-left: 0;
        border-left: none;
      }
    }
  }
}

#relevantLinks {
  padding-top: 48px;

  h2 {
    margin-top: 0;
    margin-bottom: 24px;
  }

  ul {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $CONTENT_ELEMENT_MARGIN_BOTTOM 24px;

    & > li:before {
      display: none;
    }

    [class='card'] {
      min-height: 122px;
      padding-bottom: 16px;
    }

    @include mobile {
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }

  &:before {
    background-color: $GREY_01;
    height: 140%;
  }
}
