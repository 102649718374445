/*
  Filter.module.scss

  This file contains all scoped style for Filter.jsx.
*/

@import '../../Styles/global';

.filterContainer {
  margin-top: 32px;
  padding-bottom: 16px;
  border-bottom: 2px solid $GREEN_02;

  .exportCSVButton {
    display: inline-flex;
    justify-content: center;
    gap: 8px;
    p {
      margin:0;
    }
  }
  

  .smallerText {
    font-size: font-size(-1);
  }

  .filterSection {
    display: grid;
    grid-template-columns: 3fr 1fr;
    row-gap: font-size(0);
    margin-top: 8px;

    .filterTypes {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    .filterButtons {
      display: flex;
      gap: 8px;
      justify-self: end;
      align-items: flex-start;

      button {
        font-size: font-size(0);
        padding: 10px;
      }
    }

    .filterSelections {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    .filterLabel {
      justify-self: end;
      margin-top: 0;
    }
  }

  .rowStylingFilterSection {
    display: flex;
    flex-direction: column;
    row-gap: font-size(0);
    margin-top: 8px;



    .filterTypes {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    .filterButtons {
      display: flex;
      gap: 8px;
      justify-self: end;
      align-items: flex-start;

      .groupedButtons{
        display:flex
      }

      button {
        font-size: font-size(0);
        padding: 10px;
      }
    }

    .seperatedGroup{
      justify-content: space-between;
    }

    .filterSelections {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    .filterLabel {
      justify-self: end;
      margin-top: 0;
    }
  }
}


