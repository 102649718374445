/*
  ProfilePage.module.scss

  This file contains all style for ProfilePage.jsx.
  All scoped style within page not found can be found here.
*/

@import '../../Styles/global';

#mainLayout {
  display: grid;
  grid-template-columns: 1fr 288px;
  gap: 28px;

  @include mobile {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .itemHolder {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px 16px;
    padding-left: 0;

    li {
      &:before {
        display: none;
      }
    }

    @include mobile {
      flex-direction: column;
    }
  }

  #jumpToSection {
    margin-top: 32px;

    a {
      position: relative;
      padding-right: 42px;

      svg {
        height: 16.4px;
        width: 16.4px;
        position: absolute;
        top: 50%;
        right: 13.8px;
        transform: scale(-1) translateY(50%);
      }
    }
  }

  #sectionsHolder {
    padding: $MAIN_PADDING_BOTTOM 0 0;

    @include mobile {
      padding-top: 32px;
    }
  }

  #sectionsHolder {
    section {
      & + section {
        margin-top: $MAIN_PADDING_BOTTOM;

        @include mobile {
          margin-top: 32px;
        }
      }

      .sectionHeading {
        padding-bottom: 16px;
        margin-bottom: 24px;
        border-bottom: 2px solid $GREEN_02;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 16px;

        h2 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .itemHolder {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        .hide {
          display: none;
        }

        .display {
          display: block;
          border: 1px solid $GREY_03;
          height: fit-content;
          background-color: $WHITE;
          li {
            padding: 0;
          }
        }

        &.entity {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          @include tablet {
            grid-template-columns: repeat(1, 1fr);
          }
          

          img {
            grid-column: 2;

            @include mobile {
              grid-row: 2;
              grid-column: 1;
            }
          }

          a {
            display: grid;
            grid-template-columns: 6fr 1fr;
            column-gap: 20px;

            @include mobile {
              display: grid;
              grid-template-rows: 1fr 60px;
              padding-left: 5px !important;
              grid-template-columns: 1fr !important;
            }

            @include tablet {
              grid-template-columns: 4fr 1fr;
            }
          }

          span {
            grid-row: 1;
          }

          .entityLoadingImage {
            margin-bottom: 14px;
            margin-top: 6px;
            margin-right: 8px;
            grid-column: 2;
            grid-row: 1;
            border-radius: 4px;
            width: 128px;

            @include mobile {
              width: 80px;
              height: 54px;
              grid-row: 2;
              margin-top: 8px;
              grid-column: 1;
              margin-left: auto;
              margin-bottom: 8px;
            }

            @include tablet {
              margin-right: 6px;
            }
          }
        }

        @include tablet {
          grid-template-columns: repeat(2, 1fr);
        }

        @include mobile {
          display: flex;
        }

        & > li {
          display: flex;

          a {
            width: 100%;

            &.notDocumentLink {
              & > div {
                margin-left: auto;
              }

              img {
                order: -1;

                @include tablet {
                  width: 100%;
                }
              }

              [class='cardTitle'] {
                margin-bottom: 0 !important;
                font-size: font-size(-1);
              }

              @include tablet {
                padding-left: 24px;

                &:before {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

.webLinkSvg {
  width: 24px;
  height: 24px;
  margin-left: 5px;
  vertical-align: middle;
}

.textColor {
  color: $BLACK !important;
  &:hover,
  &:focus-within {
    color: $BLACK !important;
  }
}

.entityCoverImg {
  display: flex;
  width: 270px;
  height: 180px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 1px solid var(--tones-grey-03, #bababa);
  background: $GREEN_01;
}
