/*
  HorizontalScroll.module.scss

  This file contains all scoped style for HorizontalScroll.jsx.
*/

@import '../../Styles/global';

.horizontalContainerWrapper {
  position: relative;
  width: 100%;

  .scrollbar {
    overflow-x: auto;
    overflow-y: hidden;
    position: sticky;
    width: 100%;
    z-index: 1;
    bottom: 0;

    // Use normal scrolling in mobile, so do not display this
    @include mobile {
      display: none;
    }
  }

  .horizontalContainer {
    --SCROLL-AMOUNT: 0px;
    width: 100%;
    height: 100%;
    position: absolute;

    .horizontalScroll {
      width: 100%;
      height: calc(100% - 16px);
      position: relative;
      transition: transform 0.1s ease-in-out;

      .scrollContainer {
        width: fit-content;
        transform: translateX(calc(-1 * var(--SCROLL-AMOUNT)));

        // Use normal scrolling in mobile
        @include mobile {
          max-width: 100%;
          overflow-x: auto;
        }
      }
    }
  }
}
