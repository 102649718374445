/*
  _typography.scss

  This file defines typography of the site.
*/
/*
===== TABLE OF CONTENT =====
1. BODY
2. HEADINGS
3. LINK
4. LIST ITEM
5. NO CONTENT
*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

#TPApp {
  font-size: font-size(0);
  color: $BODY_TEXT;
  font-weight: $REGULAR;
  line-height: $BASE_LINE_HEIGHT;

  @include mobile {
    font-size: font-size(-1);
  }

  * {
    :last-child {
      margin-bottom: 0;
    }
  }
}

@include print {
  :root {
    font-size: $PRINT_TYPE_SIZE;
    line-height: $PRINT_LINE_HEIGHT;

    #TPApp {
      font-size: $PRINT_TYPE_SIZE;
      line-height: $PRINT_LINE_HEIGHT;
    }
  }
}

/*==============================================================
1. BODY
==============================================================*/
p {
  margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;
  margin-top: $CONTENT_ELEMENT_MARGIN_BOTTOM;

  @include print {
    line-height: $PRINT_LINE_HEIGHT;
    margin-top: $PRINT_ELEMENT_MARGIN;
    margin-bottom: $PRINT_ELEMENT_MARGIN;
  }

  &:first-child {
    margin-top: 0;
  }

  &:empty {
    display: none;
  }
}

b {
  font-weight: $BOLD;
}

html {
  scroll-behavior: smooth;
}

/*==============================================================
2. HEADINGS
==============================================================*/

%h1 {
  font-size: font-size(6);
  font-weight: $MEDIUM;
  line-height: 1.125;
  padding-bottom: $BASE_TYPE_SIZE;
  border-bottom: 2px solid $GREEN_02;
  margin-bottom: rem(32);

  @include print {
    font-size: font-size(6, $PRINT_TYPE_SCALE);
  }
}

%h2 {
  font-size: font-size(5);
  font-weight: $BOLD;
  line-height: 1.21428;

  @include print {
    font-size: font-size(5, $PRINT_TYPE_SCALE);
  }
}

%h3 {
  font-size: font-size(4);
  font-weight: $BOLD;
  line-height: 1.23077;

  @include print {
    font-size: font-size(4, $PRINT_TYPE_SCALE);
  }
}

%h4 {
  font-size: font-size(3);
  font-weight: $BOLD;
  line-height: 1.25;

  @include print {
    font-size: font-size(3, $PRINT_TYPE_SCALE);
  }
}

%h5 {
  font-size: font-size(2);
  font-weight: $SEMI_BOLD;
  line-height: 1.4;

  @include print {
    font-size: font-size(2, $PRINT_TYPE_SCALE);
  }
}

%h6 {
  font-size: font-size(1);
  font-weight: $SEMI_BOLD;
  line-height: 1.44444;

  @include print {
    font-size: font-size(1, $PRINT_TYPE_SCALE);
  }
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    @extend %h#{$i};
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    position: relative;

    @if $i != 1 {
      margin-top: $HEADING_MARGIN_TOP;
      margin-bottom: $HEADING_MARGIN_BOTTOM;
    }

    @include print {
      line-height: 1.15;
      font-weight: $MEDIUM;

      @if $i != 1 {
        margin-top: calc($PRINT_ELEMENT_MARGIN * 2);
        margin-bottom: $PRINT_ELEMENT_MARGIN;
      }
    }
  }
}

/*==============================================================
3. LINK
==============================================================*/
a,
button.a {
  font-family: inherit;
  font-size: inherit;
  color: $LINK;
  display: inline-block;
  text-decoration: underline;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;

  &:focus {
    color: $ACCEPTED !important;
  }

  &:hover {
    color: $LINK_HOVER !important;
    text-decoration: none !important;
  }

  &:not(.alwaysFresh) {
    &:visited {
      color: $LINK_VISITED;

      &:hover {
        color: $INFORMATION;
      }
    }
  }

  &.footnote {
    margin-left: 0.3rem;
  }
}

// footnote link
sup > a,
a.footnote {
  &:visited {
    color: $LINK !important;

    &:hover {
      color: $LINK_HOVER !important;
    }
  }
}

// link specific
a[target='_blank'] {
  svg.newTabIcon {
    --ICON_SIZE: 24px;
    display: block;
    content: 'Open in new tab';
    font-size: 0;
    height: var(--ICON_SIZE);
    width: var(--ICON_SIZE);
    float: right;
    transform: scale(0.7);
  }
}

p {
  a {
    display: revert;

    &[target='_blank'] {
      svg.newTabIcon {
        --ICON_SIZE: 1rem;
        display: inline;
        vertical-align: middle;
        transform: translateY(-2px);
        float: revert;
      }
    }
  }
}

:target {
  scroll-margin-top: 100px; // scroll to top - 100px (due to nav bar size)
}

/*==============================================================
4. LIST ITEM
==============================================================*/
menu {
  padding-left: 0;
  margin-bottom: 0;
}

ul {
  & > li:before {
    content: '•'; // default
  }

  &.square > li:before {
    content: '▪';
  }

  &.circle > li:before {
    content: '○';
  }

  &.dash > li:before {
    content: '-';
  }
}

ol {
  & > li:before {
    content: counter(item) '.'; // default
  }

  &.decimalSection > li:before {
    content: counters(item, '.') '.';
  }

  &.lowerRoman > li:before {
    content: counter(item, lower-roman) '.';
  }

  &.lowerBracketedRoman > li:before {
    content: '(' counter(item, lower-roman) ')';
  }

  &.lowerSingleBracketRoman > li:before {
    content: counter(item, lower-roman) ')';
  }

  &.lowercaseLatin > li:before {
    content: counter(item, lower-alpha) '.';
  }

  &.lowercaseBracketedLatin > li:before {
    content: '(' counter(item, lower-alpha) ')';
  }

  &.lowercaseSingleBracketedLatin > li:before {
    content: counter(item, lower-alpha) ')';
  }
}

ul,
ol {
  list-style-type: none;
  counter-reset: item;
  padding-left: rem(8);

  & > li {
    display: table;

    &:before {
      display: table-cell;
      padding-right: 0.6rem; // two spaces amount
      counter-increment: item;
      vertical-align: top;
    }

    ul,
    ol {
      padding-left: 0;
    }
  }

  &.footnotesWrapper {
    display: block;
    font-size: font-size(-1);
    color: $GREY_05;
    padding-left: 0;

    sup {
      margin-right: 0.3rem;
    }

    & > li {
      margin-bottom: 8px;

      &:before {
        display: none;
      }

      & > p {
        // footnote links
        & > a {
          color: $BLACK;

          &:visited {
            color: $BLACK !important;
          }

          & > svg {
            margin-left: 5px;
            height: 12px;
            width: 12px;
          }
        }
      }

      .tableFootnoteContentWrapper {
        sup {
          float: left;
        }

        & > *:nth-last-child(2) {
          display: contents;
        }
      }
    }
  }
}

/*==============================================================
5. NO CONTENT
==============================================================*/
.noContentOutlineInside {
  outline: 4px solid $ERROR;
  outline-offset: -4px;
}

.noContentOutlineOutside {
  outline: 4px solid $ERROR;
}
