/*
  FeedbackForm.module.scss

  This file contains all scoped style for FeedbackForm.jsx.
*/

@import '../../Styles/global';

#feedbackForm {
  position: relative;

  .submit {
    margin-left: auto;
    display: block;
  }

  .radioList {
    padding-left: 0;
    margin-top: 8px;
    display: flex;
    gap: 8px 40px;
    flex-wrap: wrap;

    .radioWrapper {
      &:before {
        display: none;
      }

      display: flex;
      margin-bottom: 8px;
    }
  }

  .submittedMessge {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    text-align: center;

    .modal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $WHITE;
      border: 1px solid $BLACK;
      border-radius: 4px;
      padding: 16px;
    }
  }
}
