/*
  Accordion.module.scss

  This file contains all scoped style for Accordion.jsx.
*/

@import '../../Styles/global';

.accordion {
  $CONTENT_AREA_PADDING_TOP_BOTTOM: 11px;
  --arrow-color: #{$PRIMARY};

  // shared style between button and content area
  %shared {
    display: block;
    width: 100%;
    border-left: $ACCORDION_SIDE_BORDER_WIDTH solid $BACKGROUND;
    padding: $CONTENT_AREA_PADDING_TOP_BOTTOM 24px $CONTENT_AREA_PADDING_TOP_BOTTOM calc(24px - #{$ACCORDION_SIDE_BORDER_WIDTH});
  }

  %buttonText {
    color: $BLACK;
    font-weight: $MEDIUM;
    font-size: font-size(-1);
  }

  // drop down accordion triangle
  .triangle {
    position: relative;
    color: $PRIMARY;
    padding: 0;

    svg {
      width: 24px;
      height: 24px;
      color: inherit;
    }

    &[aria-expanded='true'] {
      svg {
        transform: scale(-1);
      }
    }
  }

  // accordion button
  & > .accordionButton {
    @extend %shared;
    @extend %buttonText;
    background-color: $WHITE;
    border-radius: 0;
    text-align: initial;
    position: relative;
    display: flex;
    justify-content: space-between;

    a {
      @extend %buttonText;
      text-decoration: none;
      margin-right: 20px; // so that text does not overlap accordion button

      &:empty {
        // add empty content to ensure accordion height still stands without content
        &:before {
          content: '';
          display: inline-block;
        }
      }
    }

    &.isOpen {
      background-color: $GREEN_01;
      color: $BLACK;
      border-color: $GREEN_04;

      &:hover {
        border-color: $PRIMARY;
      }
    }

    &.active {
      border-color: $SECONDARY;
      background-color: $YELLOW_01;

      &:hover {
        background-color: $YELLOW_02;
        border-color: $YELLOW_03;
      }
    }

    &.buttonTitleNoLink {
      padding: 0;
    }

    &:hover {
      background-color: $GREEN_01;
      color: $BLACK;
      border-color: $GREEN_04;
    }

    &:disabled {
      background-color: $WHITE;
      border-color: $GREY_02;
      text-decoration: none;

      &:hover {
        color: $GREY_05;
      }
    }

    & > button {
      &.titleNoLink {
        width: 100%;
        @extend %buttonText;
        position: relative;
        min-height: revert;
        display: flex;
        padding: 11px 24px 11px 20px;
        text-align: left;

        &[aria-expanded='true'] {
          svg {
            transform: translateY(-50%) scale(-1);
          }
        }

        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 44px;
          color: $PRIMARY;
        }

        &:hover {
          background-color: transparent;
          color: $BLACK !important;
        }

        &:focus {
          background-color: transparent;
        }
      }

      font-size: 0;
      height: 100%;
      min-height: 44px;
      width: 44px;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;

      &:focus {
        background-color: $GREY_01;
        color: $BLACK;
      }

      &:hover {
        background-color: $GREEN_04;
        color: $WHITE;

        &:before {
          border-top-color: $WHITE;
        }
      }
    }
  }

  // accordion content area
  & > div:not(.accordionButton) {
    @extend %shared;
    background-color: $GREY_01;
    transition: all $TRANSITION_STYLE $TRANSITION_TIMING;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
    overflow: hidden;
    display: none;

    &.isOpen {
      padding-top: $CONTENT_AREA_PADDING_TOP_BOTTOM;
      padding-bottom: $CONTENT_AREA_PADDING_TOP_BOTTOM;
      max-height: none;
    }

    &.contentShow {
      display: block;
    }

    &.noWrapperStyle {
      padding: 0;
      border: none;
      background-color: unset;
    }
  }

  @include tablet {
    & > .accordionButton,
    & > div:not(.accordionButton) {
      padding: 11px 20px 11px 16px;
    }
  }

  // style for mobile side nav style
  &.mobileSideNav {
    & > .accordionButton {
      border-radius: 4px;
      border: 0;
      padding: 11px 20px 11px;
      background-color: $GREEN_01;

      &[aria-expanded='true'] {
        border-radius: 4px 4px 0 0;
        &:before,
        &:after {
          border: 0;
          width: 21.07178px;
          height: 2px;
          background-color: var(--arrow-color);
          position: absolute;
          top: 50%;
          right: 14.55px;
          transform-origin: center;
        }

        &:before {
          transform: translateY(-50%) rotate(45deg);
        }

        &:after {
          display: block;
          content: '';
          transform: translateY(-50%) rotate(-45deg);
        }
      }

      a {
        text-decoration: underline;
        font-weight: $SEMI_BOLD;
      }
    }

    & > div:not(.accordionButton) {
      border-radius: 0 0 4px 4px;
      border: 0;
      padding: 16px 0 24px;
      background-color: $WHITE;

      @include tablet {
        // wrap to tablet and below to make sure no side effect on other resolution
        &.isOpen {
          overflow: visible;
        }
      }
    }
  }
}
