/*
  LoadingMenu.module.scss

  This file contains all scoped style for LoadingMenu.jsx.
*/

@import '../../Styles/global';

.loadingSideNav {
  display: block;
  padding-left: 0;

  & > li {
    display: list-item;
    margin: 0;
    width: 100%;
    padding: 11px 24px;
    animation: flashing 1s infinite;

    &:before {
      display: none;
    }

    .invisibleTitle {
      opacity: 0.001;
    }
  }

  & > li:nth-child(2) {
    animation-delay: 0.1s;
  }
  & > li:nth-child(3) {
    animation-delay: 0.2s;
  }
  & > li:nth-child(4) {
    animation-delay: 0.3s;
  }
  & > li:nth-child(5) {
    animation-delay: 0.4s;
  }
  & > li:nth-child(6) {
    animation-delay: 0.5s;
  }
  & > li:nth-child(7) {
    animation-delay: 0.6s;
  }
  & > li:nth-child(8) {
    animation-delay: 0.7s;
  }
  & > li:nth-child(9) {
    animation-delay: 0.8s;
  }
}

@keyframes flashing {
  0% {
    background: $GREEN_01;
  }
  100% {
    background-color: none;
  }
}
