/*
  Modal.module.scss

  This file contains all scoped style for Modal.jsx.
*/

@import '../../Styles/global';

#modal-view {
  $BUTTON_HEIGHT: 44px;
  $ICON_HEIGHT: 24px;
  --ANIMATION-TIME: 0.8s;

  @keyframes modal-fade {
    0% {
      opacity: 0.2;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.6;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: $MODAL_OVERLAY_Z_INDEX;
    background: rgba(0, 0, 0, 0.6);
    animation: modal-fade var(--ANIMATION-TIME) ease-in;
  }

  .modal {
    position: fixed;
    top: 10%;
    left: 10%;
    width: 80%;
    max-height: 80%;
    z-index: $MODAL_OVERLAY_Z_INDEX;
    background: $WHITE;
    overflow: hidden;
    border-radius: 4px;
    animation: modal-fade var(--ANIMATION-TIME) ease-in;

    @include mobile {
      top: 5%;
      left: 5%;
      width: 90%;
    }

    .modal-content {
      display: flex;
      flex-direction: column;

      .header {
        text-align: right;
      }

      button {
        height: $BUTTON_HEIGHT;
        width: $BUTTON_HEIGHT;
        font-size: 0;
        padding: 0;
        margin: $CONTENT_ELEMENT_MARGIN_BOTTOM;

        svg {
          height: $ICON_HEIGHT;
          width: $ICON_HEIGHT;
        }
      }

      .content {
        overflow: auto;
        max-height: calc(80vh - ($BUTTON_HEIGHT + 2 * ($CONTENT_ELEMENT_MARGIN_BOTTOM))); // based on current button height and padding
      }
    }
  }
}
