/*
  PreviewPDFControl.module.scss

  This file contains all scoped style for PreviewPDFControl.jsx.
*/

@import '../../Styles/global';

#previewARcontrolPDF {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 8px;

  button {
    display: inline-flex;
    justify-content: center;
    gap: 8px;
  }
}
