/*
  _animations.scss

  have common animations
*/

.slideUp {
  position: fixed;
  animation: slideUp 0.35s ease-out;

  @keyframes slideUp {
    0% {
      top: 100vh;
    }
    100% {
      top: 0;
    }
  }
}

.slideUpToMiddle {
  position: relative;
  animation: slideUpToMiddle 0.35s ease-out;
  margin-left: auto;
  margin-right: auto;

  @keyframes slideUpToMiddle {
    0% {
      top: 100vh;
    }
    100% {
      top: 50vh;
      transform: translateY(-50%);
    }
  }
}

.slideDown {
  position: fixed;
  animation: slideDown 0.35s ease-out;

  @keyframes slideDown {
    0% {
      top: 0;
    }
    100% {
      top: 100vh;
    }
  }
}

.slideDownToMiddle {
  position: relative;
  animation: slideDownToMiddle 0.35s ease-out;
  margin-left: auto;
  margin-right: auto;

  @keyframes slideDownToMiddle {
    0% {
      top: 50vh;
      transform: translateY(-50%);
    }
    100% {
      top: 100vh;
    }
  }
}

.slideLeft {
  position: fixed;
  animation: slideLeft 0.5s ease-out;

  @keyframes slideLeft {
    0% {
      left: 100vw;
    }
    100% {
      left: 0;
    }
  }
}

.slideRight {
  position: fixed;
  animation: slideRight 0.5s ease-out;

  @keyframes slideRight {
    0% {
      left: 0;
    }
    100% {
      left: 100vw;
    }
  }
}

.fadeIn {
  @keyframes fade-in {
    0% {
      opacity: 0.2;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.6;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 0.8s ease-in;
}

.fadeOut {
  @keyframes fade-out {
    0% {
      opacity: 0.8;
    }
    25% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 0;
    }
  }
  animation: fade-out 0.8s ease-in;
}
