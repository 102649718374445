/*
  AnnualReportCover.module.scss

  This file contains all scoped style for AnnualReportCover.jsx.
*/

@import '../../Styles/global';

#reportTitle {
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 48px;

  // upper h1 part
  & > span {
    display: inherit;
    gap: inherit;
    font-size: font-size(3);
    flex-wrap: wrap;
    font-weight: $SEMI_BOLD;

    @include mobile {
      gap: 8px;

      #departmentName {
        width: 100%;
      }
    }
  }

  [class='noContentOutlineInside'] {
    font-size: inherit;
    min-height: font-size(6);
  }

  @include tablet {
    margin-bottom: 24px;
    margin-top: 0;
  }
}

#annualReportCover {
  display: grid;
  grid-template-columns: 1fr minmax(200px, 496px);
  column-gap: 10.4575%;
  flex-grow: 1;
  
  .accordionExportButtonWrapper{
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  .coverImage {
    width: 100%;

    & > * {
      border: 1px solid $GREY_03;
    }
  }

  @include tablet {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;

    .coverImage {
      width: 50%;
    }
  }
}

// This is for ironPDF from BE to pick up to identify page is ready for print
[id='readyForPrint'] {
  display: none;
}

@include print {
  #annualReportCover {
    display: block;
    width: 100%;

    & > :not(.coverImage) {
      display: none;
    }

    .coverImage {
      width: 100vw;
      height: 100vh;
      overflow: hidden;

      svg,
      img {
        width: 100vw;
        border: 0;
        page-break-after: avoid;
      }
    }
  }
}
