/*
  CompareFunction.module.scss

  This file contains all scoped style for CompareFunction.jsx.
*/

@import '../../Styles/global';

#compareFunction {
  --MAX-LINE: 1;
  background-color: $WHITE;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.1);
  max-width: $MAX_HEADER_FOOTER_CONTENT_WIDTH;
  max-height: min(calc(100vh - 40px), 608px);
  height: calc(100%);
  width: calc(100vw - 40px);

  @include mobile {
    max-height: calc(100vh - 40px);
  }

  .carousel {
    height: calc(100% - 65px);
  }

  .limitTextLines {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--MAX-LINE);
    overflow: hidden;
  }

  .header {
    min-height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    justify-content: space-between;
    background-color: $BACKGROUND;
    border-radius: 0;

    h4 {
      margin: 0;
    }

    .headerButtons {
      display: flex;
      gap: 16px;

      button {
        height: 44px;

        &.noText {
          font-size: 0;
          border: 0;
        }

        svg {
          height: 24px;
          width: 24px;
          color: $PRIMARY;
        }
      }
    }
  }

  %carouselItemHeader {
    padding: 16px 24px;
    border-bottom: 4px solid $BACKGROUND;
    min-height: 132px;

    @include mobile {
      min-height: 96px;
    }
  }

  .mandatoryRules {
    .ruleHeader {
      @extend %carouselItemHeader;

      a {
        color: $BLACK;
        margin-bottom: 12px;
        padding: 0;
        text-align: left;

        svg {
          height: 18px;
          width: 18px;
          margin-left: 6px;
          vertical-align: middle;
        }
      }

      .information {
        display: inline-block;
      }
    }

    .ruleList {
      padding: 0;
      li {
        display: list-item;
        margin: 0;
        width: 100%;
        border-bottom: 1px solid $BACKGROUND;
        background-color: $GREY_01;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: $BACKGROUND;
        }

        &:before {
          display: none;
        }

        button {
          padding: 16px 24px;
          width: 100%;
          color: $BLACK;
          text-decoration: none;
          text-align: left;

          &:hover {
            color: $BLACK !important;
            text-decoration: none !important;
          }

          &.selectedRule {
            border-color: $SECONDARY;
            background-color: $YELLOW_01 !important;
          }
        }
      }
    }

    .noRules {
      display: block;
      padding: 16px 24px;
      text-align: center;
    }
  }

  .tags {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    flex-wrap: wrap;
  }

  .currentAnnualReport {
    .arHeader {
      @extend %carouselItemHeader;
      background-color: $YELLOW_01;
      border-color: $SECONDARY;

      a {
        padding: 0;
      }
    }

    .arContent {
      padding: 32px;
    }
  }

  .compareAnnualReport {
    .compareArHeader {
      @extend %carouselItemHeader;
      padding-top: 24px;

      .editReportForm {
        display: grid;
        grid-template-columns: 44px 1fr;
        gap: 16px;
        align-items: center;

        &.alignTop {
          align-items: flex-start;
        }

        .editButton {
          height: 44px;
          font-size: 0;
          color: $WHITE;

          @include mobile {
            height: 32px;
            width: 32px;
            padding: 0;
          }

          &:focus {
            color: $BLACK;
          }

          svg {
            height: 24px;
            width: 24px;
          }
        }

        .formDropdowns {
          .selectEntity {
            font-size: font-size(-1);
          }

          .expandButton {
            position: relative;
            padding: 0;
            width: 100%;
            text-align: end;
            height: 32px;

            svg {
              width: 32px;
              height: 32px;
              color: inherit;
            }

            &[aria-expanded='true'] {
              svg {
                transform: scale(-1);
              }
            }
          }

          .compareLinks {
            display: flex;
            flex-direction: column;
            gap: 8px;

            a {
              width: fit-content;
              padding: 0;
              text-align: left;
            }
          }

          .editFields {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .formField {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .invisibleTitle {
                width: 100px;

                span {
                  opacity: 0.001;
                }
              }

              .loadingDropdown {
                min-height: 48px;
              }

              .fieldTitle {
                font-weight: $MEDIUM;
              }

              .saveEdits {
                display: grid;
                grid-template-columns: 1fr max-content;
                column-gap: 16px;

                @include mobile {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  .saveButton {
                    width: fit-content;
                    align-self: end;
                  }
                }
              }

              ul {
                font-size: font-size(0);
              }
            }
          }
        }
      }
    }

    .compareArContent {
      padding: 32px;

      .reportContentLoading {
        width: 100%;
        height: 600px;
      }
    }
  }
}

.centerAligned {
  text-align: center;
}
