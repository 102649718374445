/*
  PdfEmbed.module.scss

  This file contains all scoped style for PdfEmbed.jsx.
*/

@import '../../Styles/global';

.loadingIndicator {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: flashing 1.2s infinite;
}

.noTableContents {
  padding: 10px;
}

.pdfEmbed {
  display: flex;
  flex-direction: column;
}

.menuButton {
  width: 100%;

  svg {
    width: 24px;
    height: 24px;
  }
}

.menu {
  position: sticky;
  z-index: 100;
  top: 80px;
}

.outlineWrapper {
  display: none;
  position: absolute;
  width: 50%;
  height: fit-content;
  max-height: 400px;
  top: 60px;
  left: 0;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $GREY_01;
  color: $BLACK;

  @include mobile {
    width: 100%;
  }

  &.showOutline {
    display: block;
  }

  ul {
    padding: 0;
    list-style-type: none;

    li {
      &::before {
        content: '';
        padding: 0;
      }
      width: 100%;

      ul {
        padding-left: 20px;
      }

      a {
        color: $BLACK;
        background-color: $GREY_01;
        border-left: 4px solid $BACKGROUND;
        padding-left: 8px;
        width: 100%;
        padding: 11px 24px;
        text-decoration: none;

        &:hover {
          color: $BLACK !important;
          text-decoration: underline !important;
          background-color: $BACKGROUND;
          border-left-color: $PRIMARY;
        }
      }
    }
  }
}

.hidden {
  display: none;
}

@keyframes flashing {
  0% {
    background: $WHITE;
  }
  100% {
    background-color: $GREEN_01;
  }
}
