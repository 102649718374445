/*
  AnnualReportArticle.module.scss

  This file contains all scoped style for AnnualReportArticle.jsx.
*/

@import '../../Styles/global';

[id='annualReportHolder'] {
  display: grid;
  $ARTICLE_CONTENT_WIDTH: 808px;
  grid-template-columns: $SIMPLE_BANNER_BRAND_MARK_WIDTH + $SIMPLE_BANNER_GAP 1fr minmax(
      $ARTICLE_CONTENT_WIDTH - $SIMPLE_BANNER_BRAND_MARK_WIDTH,
      $ARTICLE_CONTENT_WIDTH + $SIMPLE_BANNER_GAP
    );

  & > div {
    grid-column: 1;
    border-right: 2px solid $BACKGROUND;
    padding-right: $SIMPLE_BANNER_GAP;
    padding-top: 48px;

    @include tablet {
      border: 0;
      padding: 0;
    }
  }
  .accordionExportButtonOuterWrapper{
    padding-bottom:8px;
    .accordionExportButtonWrapper{
      display: flex;
      flex-direction: column;
      padding: 8px;
    }
  }


  .articleColumn {
    display: flex;
    flex-direction: column;
    grid-column: 3;
    border-right: none;
    max-width: $ARTICLE_CONTENT_WIDTH + $SIMPLE_BANNER_GAP;
    padding-left: $SIMPLE_BANNER_GAP;
    padding-right: 0;

    @include print {
      max-width: 100%;
    }

    @include tablet {
      max-width: 100%;
      padding-left: 0;
    }

    & > article {
      .articleContent {
        // for ANAO chapter content type which contains pdf for external report only
        .pdf {
          margin-top: 1rem;
          width: 100%;
          height: 85vh;
        }

        .pageFootnoteWrapper {
          margin-top: 1rem;
          padding: 0.5rem 0;
          border-top: 1px solid $BACKGROUND;
        }
      }
    }
  }

  .pageNavigation {
    margin-top: $ARTICLE_CONTENT_MARGIN_BOTTOM_DESKTOP;

    @include mobile {
      margin-top: $ARTICLE_CONTENT_MARGIN_BOTTOM_MOBILE;
    }
  }

  @include tablet {
    display: flex;
    flex-direction: column;
    row-gap: 33px;
  }

  @include print {
    display: block;
    width: 100vw;

    & > div:not(.articleColumn),
    & ~ *,
    .pageNavigation {
      display: none;
    }

    .articleColumn {
      all: unset;
    }

    .chapterHolder {
      break-before: page;
    }

    h1 {
      &.chapterTitle {
        padding-bottom: 24px;
        border-color: $GREY_04;
        margin: calc($PRINT_ELEMENT_MARGIN * 4) 0;
      }

      &.sectionTitle {
        padding-bottom: 0;
        font-size: font-size(5.5, $PRINT_TYPE_SCALE);
        border-bottom: none;
        margin: calc($PRINT_ELEMENT_MARGIN * 4) 0;
      }
    }
  }
}

// This is for ironPDF from BE to pick up to identify page is ready for print
[id='readyForPrint'] {
  display: none;
}

.articleContentLoading {
  min-height: 200px;
}

#compareModal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  z-index: $SKIP_BUTTON_Z_INDEX + 1;
  padding: $MIN_CONTENT_SIDE_PADDING;
  font-size: font-size(-1);
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    margin-right: auto;
    margin-left: auto;
  }
}

.contentLoading {
  min-height: 200px;
}
