@import './Styles/_global.scss';
@import './Styles/typography.scss';
@import './Styles/_elements.scss';
@import './Styles/_customElements.scss';
@import './Styles/_spinner.scss';
@import './Styles/_animations';
@import './Styles/PrintOnlyStyles/_annualReport.scss';

#TPApp {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  // NOTE: `#betaNote` style is not used anymore but keeping it just in case if we need to add it back for any sort of global notification message on app wide
  #betaNote {
    background-color: $PRIMARY;
    color: $WHITE;
    z-index: $HEADER_Z_INDEX + 1;
    text-align: center;
    padding: 3px 20px;

    a {
      color: $YELLOW !important;

      &:visited {
        color: $YELLOW !important;
      }

      &:focus {
        color: $YELLOW_02 !important;
      }

      &:hover {
        color: $YELLOW_01 !important;
      }
    }
  }

  main {
    flex-grow: 1;
    margin: auto;
    max-width: $MAX_CONTENT_WIDTH + ($MIN_CONTENT_SIDE_PADDING * 2);
    width: 100vw;
    padding: $CONTENT_ELEMENT_MARGIN_BOTTOM $MIN_CONTENT_SIDE_PADDING $MAIN_PADDING_BOTTOM;
    position: relative;
    display: flex;
    flex-direction: column;

    @include mobile {
      padding-top: 8px;
    }
  }

  mark {
    background-color: $SECONDARY;
  }
}

* {
  box-sizing: border-box;
  margin-top: 0;

  &:focus {
    z-index: 1;
    outline-color: $LINK_HOVER;
    outline-style: solid;
    outline-width: $FOCUS_OUTLINE_WIDTH;
  }

  .linkCopyWrapper {
    position: relative !important;
  }

  // if this is applied in section, it will have green background which goes from left to right edge
  .fullColorBackground {
    position: relative;

    &:before {
      content: '';
      display: block;
      background-color: $GREEN_01;
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }
}

::-webkit-scrollbar {
  background: $BACKGROUND;
  border: 4px solid rgba(0, 0, 0, 0);
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-thumb {
  background: $PRIMARY;
  -webkit-border-radius: 16px;
  // -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  border: 3px solid $BACKGROUND;

  &:hover {
    background: $GREEN_04;
  }
}

::-webkit-scrollbar-corner {
  background: $BACKGROUND;
}

// NOTE: below is just for PDF export. As BE need system id to be displayed but visually not visible without using disply none nor use html hidden attribute hence BE can pick up where the target element is located on which page
// Trade off is when user drag entire content and do copy and paste, whatever text that is visually hidden will be shown.
.invisibleForPdfExport,
.invisibleForWordExport {
  display: block;
  color: white;
  font-size: 1px !important;
  line-height: 1px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.breakPage {
  break-after: page;
}
