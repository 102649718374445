/*
  TabView.module.scss

  This file contains all scoped style for TabView.jsx.
*/

@import '../../Styles/global';

.tabViewContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  margin-top: 32px;

  .tab {
    min-height: 238px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $GREY_04;
    border-radius: 4px;
    margin-bottom: 16px;

    // class added when tab is expanded
    &.isActive {
      border-bottom: 0;
      margin-bottom: 0;
      border-radius: 4px 4px 0 0;

      &:after {
        // after allows for covering border of content box
        content: '';
        width: 100%;
        height: 20px;
        position: relative;
        background: $WHITE;
        top: 5%;
      }
    }

    .tabInfo {
      min-height: 238px;
      height: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        color: $BLACK;
        font-size: font-size(2);
        margin-bottom: 1rem;
      }

      span {
        font-size: font-size(-1);
        margin: 0;
        display: block;
      }

      .tabFooter {
        display: flex;
        margin-top: 1rem;

        button {
          margin-left: auto;
          background-color: $WHITE;
          color: $BLACK;
          height: 16px;
          width: 16px;
          padding: 0;

          svg {
            height: 100%;
            width: 100%;
            transform: rotate(90deg);
          }
        }

        a {
          color: $BLACK;
        }

        // accordion style dropdown expand icon
        .triangle {
          position: relative;

          &:before {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-left: 5.775px solid transparent;
            border-right: 5.775px solid transparent;
            border-top: 5.77px solid $BLACK;
            position: absolute;
            top: 50%;
            left: 25%;
          }

          &[aria-expanded='true'] {
            &:before {
              transform: translateY(-50%) scale(-1);
            }
          }
        }
      }
    }
  }

  .expandedContents {
    min-height: 500px;
    grid-column: 1 / -1;
    grid-row-start: var(--row-start);
    border: 1px solid $GREY_04;
    margin-bottom: 16px !important;
  }
}
