/*
  PortfolioEntitiesCompanies.module.scss

  This file contains all style for PortfolioEntitiesCompanies.jsx.
  All scoped style within page not found can be found here.
*/
@import '../../Styles/global';

.dataSetsContainer {
  .entityTypesContainers{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .entityTypes {
      display: flex;
      gap: 24px;
  
      @include mobile {
        flex-direction: column;
      }
  
      span {
        display: flex;
        flex-wrap: wrap;
  
        a {
          color: $BLACK;
          display: flex;
          align-items: center;
          margin-left: 8px;
  
          svg {
            height: 16px;
            width: 16px;
            margin-left: 4px;
          }
        }
      }
    }
  }

}

.pecBody {
  margin-top: 12px;
  display: grid;
  column-gap: 24px;
  position: relative;

  @include tablet {
    display: flex;
    flex-direction: column;
  }

  .portfolioList {
    order: -1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .portfolio {
      display: flex;
      width: 1224px;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 4px;
      border: 1px solid #387B85;
      background: #FFF;

      &>.portfolioHeader {
        display: flex;
        height: 56px;
        padding-left: 24px;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-radius: 4px 4px 0px 0px;
        background: #E1ECEB;
        cursor: pointer;

        &>.portfolioHeaderTitleWrapper {
          display: flex;
          align-items: center;
          gap: var(--Value-Unit50, 8px);
          flex: 1 0 0;
          &>span{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top:0;

            &> p{
              color: var(--Black, #000);
              font-family: Inter;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 26px; /* 144.444% */

              margin-right:1rem;
              margin-bottom: 0;
            }
          }
        }
        &>.portfolioHeaderSvgWrapper {
          display: flex;
          padding: 10px;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          &>svg{
            width: 24px;
            height: 24px;
          }
        }
      }
      &>.portfolioBody{
        width:100%;
        .portfolioDetails {
          display: flex;
          padding: 24px;
          flex-direction: row;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
  
          &>.portfolioDetailsWrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
  
            &>.portfolioStats{
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              gap: 8px;
  
              flex: 1 0 0;
              &>div{
                display:flex;
                &>.titleAndNumber {
                  color: var(--Black, #000);
                  /* Paragraph */
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px; /* 150% */
                  margin: 0;
                }
                &>.titleText {
                  color: var(--Black, #000);
                  /* Paragraph */
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  margin:0;
                }
              }
            }
  
            &>p{
              color: var(--Black, #000);
              /* Paragraph */
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 150% */
              margin:0;
            }
  
            &>.previousNames{
              height: 22px;
              align-self: stretch;
              color: #000;
  
              /* Caption + Link */
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
              text-decoration-line: underline;
            }
          }
        }
      }



      .entities {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding:24px;

        .title {
          color: var(--Black, #000);
          /* Paragraph */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          margin: 0;
        }

        ol {
          padding: 0;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 8px;

          .noEntities {
            font-size: font-size(-1);
          }

          @include mobile {
            display: flex;
            flex-direction: column;
          }

          li {
            padding-right: 16px;

            @include mobile {
              padding-right: 8px;
            }

            .whiteBorderTags {
              border-color: $WHITE !important;
            }

            .entityTitle {
              font-size: font-size(-1);
              margin-bottom: 0 !important;
            }

            .loadingImage {
              background-color: $WHITE;
              border-radius: 8px;
              border: 1px solid $GREY_03;
              height: fit-content;
              width: fit-content;

              @include mobile {
                margin-left: auto;
              }

              li {
                padding: 0;
                width: 126px; // with the borders will be 128px
                height: 20px;

                @include mobile {
                  width: 78px;
                  height: 13px; // One quarter of 54px;
                }

                &:first-child {
                  border-radius: 8px 8px 0 0;
                }

                &:last-child {
                  border-radius: 0 0 8px 8px;
                }
              }
            }

            .hide {
              display: none;
            }
          }
        }
      }
    }
  }

  .notResults {
    text-align: center;
  }
}

.abolishedButton {
  display: flex;
  min-width: 305px;
  min-height: 44px;

  input {
    margin-left: 0;
    margin-bottom: 0;
    pointer-events: none;

    &:checked {
      border-color: $PRIMARY;
    }
  }

  &:hover {
    input {
      background-color: $GREEN_01;

      &:checked {
        background-color: $PRIMARY;
      }
    }
  }
}
