/*
  PageHead.module.scss

  This file contains all scoped style for PageHead.jsx.
*/

@import '../../Styles/global';

#pageHead {
  padding: 44px 0;
  min-height: 240px;

  @include tablet {
    padding: 32px 0;
    min-height: 159px;
  }

  &:not(.mobileBackgroundImage) {
    &:after {
      @include mobile {
        display: none;
      }
    }
  }

  h1 {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    max-width: 808px;
  }

  #pageHeadExtraInfo {
    font-size: font-size(-1);
    display: flex;
    gap: 4px 16px;
    margin-bottom: 16px;
    flex-wrap: wrap;
    align-items: center;

    strong {
      font-weight: $SEMI_BOLD;
    }

    @include mobile {
      strong {
        width: 100%;
      }
    }
  }

  #childrenWrapper {
    margin-top: 16px;
    max-width: 808px;
  }
}

.defaultBackground {
  // background image
  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-image: var(--BACKGROUND-IMAGE);
    background-repeat: no-repeat;
    background-size: auto 90%;
    background-position-x: 100%;
    background-position-y: 100%;

    @include mobile {
      background-size: 136px;
      background-position-y: calc(100% + 10px);
    }
  }
}

.entityCoverImgDiv {
  grid-column: 2;
  align-self: center;
  width: 270px;
  height: 180px;
  background-color: $GREEN_01;
  padding: 8px;

  border-radius: 4px;
  border: 1px solid var(--tones-grey-03, #bababa);

  @include mobile {
    display: none;
  }
}

.entityCoverImg {
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  @include mobile {
    display: none;
  }
}

.outerEntityDiv {
  display: grid !important;
  grid-template-columns: 1fr 270px !important;

  @include mobile {
    display: flex !important;
    flex-direction: column;
  }

  // background image
  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-image: var(--BACKGROUND-IMAGE);
    background-repeat: no-repeat;
    background-size: auto 90%;
    background-position-x: 100%;
    background-position-y: 100%;

    @include mobile {
      content: none;
      background-size: 136px;
      background-position-y: calc(100% + 10px);
    }
  }
}

.entityDefaultContent {
  grid-column: 1;
}
