/*
  DefaultCoverImage.module.scss

  This file contains all scoped style for DefaultCoverImage.jsx.
*/

#defaultCoverImage {
  img {
    all: revert !important;
  }
}
