/*
  PublicationsLoading.module.scss

  This file contains all scoped style for PublicationsLoading.jsx.
*/

@import '../../Styles/global';

.loadingBanner {
  min-height: 240px;
  margin-bottom: 32px;
}

#financialRatiosLoading {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 32px;

  @include mobile {
    row-gap: 10px;
  }

  .section {
    display: flex;
    gap: 10px;

    .topLeftCard {
      height: 302px;
      max-width: 312px;
    }

    .topRightCard {
      height: 302px;
      max-width: 900px;
    }

    .bottomTitleCard {
      height: 67px;
      width: 100%;
    }

    .bottomLeftCard {
      height: 142px;
      max-width: 312px;
    }

    .bottomRightCard {
      height: 142px;
      max-width: 900px;
    }

    @include mobile {
      flex-wrap: wrap;

      .topLeftCard {
        height: 82px;
        max-width: 100%;
      }

      .topRightCard {
        height: 230px;
        margin-bottom: 8px;
      }

      .bottomTitleCard {
        margin-top: 14px;
      }

      .bottomLeftCard {
        max-width: calc(30% - 5px);
      }

      .bottomRightCard {
        max-width: calc(70% - 5px);
      }
    }
  }
}
