/*
  LoadingAnnualReportPreview.module.scss

  This file contains all scoped style for LoadingAnnualReportPreview.jsx.
*/

@import '../../Styles/global';

#findingAR {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;

  #spinnerHolder {
    transform: scale(1.5);
  }

  #message {
    font-size: font-size(2);
    text-align: center;
  }
}
