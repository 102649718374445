/*
  TablePreview.module.scss

  This file contains all scoped style for TablePreview.jsx.
*/

@import '../../Styles/global';

#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
}

.control {
  position: sticky;
  top: 0;
  left: 0;
  z-index: $HEADER_Z_INDEX;
  padding: $CONTENT_ELEMENT_MARGIN_BOTTOM $MIN_CONTENT_SIDE_PADDING;
  display: flex !important;
  gap: 8px;
  font-size: font-size(0);
  line-height: $BASE_LINE_HEIGHT;

  * {
    font-family: 'Inter', sans-serif !important;
  }
}

.wrapper {
  display: block;

  &.webView {
    max-width: 808px;

    & > div {
      border-right: none;
    }
  }
}

.messageWrapper {
  border-right: none !important;
  padding: $CONTENT_ELEMENT_MARGIN_BOTTOM 0 !important;
}

#table {
  display: block !important;
  padding: 0;
  border: 0;
}
