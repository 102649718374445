/*
  _annualReport.scss

  This file contains all global print style for annual report page only.
*/

@mixin printStyle {
  *:not(.noFontChange) {
    font-family: sans-serif !important;
  }

  all: unset;

  & > {
    header,
    footer {
      display: none;
    }
  }

  main {
    all: unset;
    display: contents;

    // hide everything that is not annual report content
    & > :not([id*='annualReportHolder'], [id*='annualReportCover']) {
      display: none;
    }

    // hide all buttons except clickable images
    button:not([class*='ImageView']) {
      display: none !important;
    }

    a {
      color: $BLACK;
      text-decoration: none;

      &:visited {
        color: $BLACK;
      }

      .newTabIcon {
        display: none;
      }
    }

    sup {
      vertical-align: super;
    }

    .linkCopyWrapper {
      margin-bottom: 16px;
      break-inside: auto;
    }

    .tableElement {
      overflow: visible;
      width: auto;
      height: auto;
      display: block;

      .tableOverflowWrapper {
        width: 100%;
        max-width: 100%;
        overflow: hidden; // FIXME: temp hideing scroll bar on table until get landscape layout solution comes
      }

      figcaption {
        break-after: avoid;
        break-inside: avoid;
        break-before: auto;
      }

      table {
        margin-bottom: 16px;
        max-width: 100% !important;

        tbody,
        tr,
        th,
        td {
          --DEFAULT-CELL-PADDING-SIZE: 8px;
          word-break: initial;
          box-sizing: border-box;
          padding-left: var(--DEFAULT-CELL-PADDING-SIZE) !important;
          padding-right: var(--DEFAULT-CELL-PADDING-SIZE) !important;
          padding-top: var(--DEFAULT-CELL-PADDING-SIZE) !important;
          padding-bottom: var(--DEFAULT-CELL-PADDING-SIZE) !important;
        }

        tr {
          break-inside: avoid;
        }

        // if table has `wideTable` class due to set width is over 100%, scale down
        &.wideTable {
          * {
            font-size: font-size(-3, $PRINT_TYPE_SCALE) !important;
          }

          tbody,
          tr,
          th,
          td {
            --DEFAULT-CELL-PADDING-SIZE: 1px;
            padding-left: var(--DEFAULT-CELL-PADDING-SIZE) !important;
            padding-right: var(--DEFAULT-CELL-PADDING-SIZE) !important;
            padding-top: var(--DEFAULT-CELL-PADDING-SIZE) !important;
            padding-bottom: var(--DEFAULT-CELL-PADDING-SIZE) !important;
            word-break: break-word;
            width: min-content !important;
          }
        }
      }
    }

    // hide footnote go back to content link
    .footnotesWrapper {
      a.footnote {
        display: none;
      }
    }
  }
}

#TPApp.forcePrintStyle {
  @include printStyle;

  & > {
    header,
    footer {
      display: block;
    }
  }
}

@include print {
  #TPApp.printOnly {
    @include printStyle;
  }
}
