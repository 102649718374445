/*
  _global.scss

  This file contains all global styles 

  NOTE: this file should be imported on each scoped module style sheet 
  for components/page components
*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import './_mixins.scss';
@import './_constants.scss';

* {
  font-family: 'Inter', sans-serif;
}
