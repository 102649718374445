/*
  SearchPageLoading.module.scss

  This file contains all scoped style for SearchPageLoading.jsx.
*/

@import '../../Styles/global';

#searchPageLoading {
  padding: 24px 0 48px;

  li {
    min-height: 170px;
  }
}
