/*
  DataSetsLoading.module.scss

  This file contains all scoped style for DataSetsLoading.jsx.
*/

@import '../../Styles/global';

.dataSetLoadingFilters {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: 24px;

  .loadingFilterDropdown {
    height: 56px;
  }
}
