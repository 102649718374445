/*
  FormField.module.scss

  This file contains all scoped style for FormField.jsx.
*/

@import '../../Styles/global';

.formField {
  display: flex;
  flex-direction: column;
  margin: 0;

  .title {
    font-size: font-size(-1);
    font-weight: 700;
    margin-bottom: 8px;

    &.required {
      &:after {
        content: '*';
        display: inline;
        color: $ERROR;
      }
    }
  }

  .subText {
    font-size: font-size(-1);
    color: $GREY_05;
    font-style: italic;
  }
}
