/*
  PublicationsList.module.scss

  This file contains all style for PublicationsList.jsx.
  All scoped style within page not found can be found here.
*/

@import '../../Styles/global';

.bannerHead {
  max-width: 808px;

  p {
    font-size: font-size(-1);
  }
}

.textAlignCenter {
  text-align: center;
}

.publicationsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 32px;

  &:empty {
    margin-bottom: 0;
  }

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  .publicationCard {
    height: 100%;
    &:before {
      display: none;
    }

    .hide {
      display: none;
    }

    .display {
      display: block;

      li {
        padding: 0;
      }
    }
  }
}

.hidePagination {
  display: none;
}
