/*
  AnnualReportTopPartLoading.module.scss

  This file contains all scoped style for AnnualReportTopPartLoading.jsx.
*/

@import '../../Styles/global';

.loadingBreadcrumb {
  min-height: 24px;
  margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;
}

.loadingBanner {
  min-height: 240px;
  margin-bottom: 48px;

  @include mobile {
    margin-bottom: 40px;
  }

  &.isAR {
    margin-bottom: 0 !important;
  }
}
