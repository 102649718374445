/*
  ListSelect.module.scss

  This file contains all scoped style for ListSelect.jsx.
*/

@import '../../Styles/global';
.listOptionsContainer {
  width: 100%;
  background: $WHITE;
  padding: 0;
  overflow: auto;
  border-radius: 4px;
  margin-bottom: 0;
}

.listSelectBase {
  list-style: none;
  width: 100%;
  border-bottom: 1px solid $BACKGROUND;

  &.hideLastBorder {
    &:last-child {
      border-bottom: none;
    }
  }

  .dropdownLabelContainer {
    display: inline-grid;

    &.tagBeforeText {
      grid-template-columns: auto 1fr;
    }

    &.tagAfterText {
      grid-template-columns: auto auto;

      span {
        &.tagAfterText {
          justify-self: flex-end;
        }
      }
    }

    // for styling tags in list select
    // will appear at the bottom of text normally (unless .tagBeforeText is added)
    .tagText {
      word-break: break-word;
      justify-self: flex-start !important;

      &.tagBeforeText {
        grid-row: 1;
        grid-column: 2;
        margin-bottom: 0;
        margin-left: 8px;
      }

      &.tagAfterText {
        grid-column: 1;
      }
    }
    .tags {
      display: flex;
      gap: 4px;

      &.hasTags {
        margin-top: 8px;
      }

      &.tagAfterText {
        justify-content: flex-end;
        margin-top: 0;
      }

      &.tagBeforeText {
        margin-top: 0;
      }
    }
  }

  &.singleSelected {
    background-color: $YELLOW_01;
  }

  &:before {
    display: none;
  }

  &:focus-within {
    label {
      z-index: 1;
      outline-color: $LINK_HOVER;
      outline-style: solid;
      outline-width: $FOCUS_OUTLINE_WIDTH;
      outline-offset: -$FOCUS_OUTLINE_WIDTH;
    }
  }

  &:not(.multiSelectList) {
    &:hover {
      background-color: $GREEN_01;
    }
  }

  label {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 16px;

    &.noCheckbox {
      display: block;
      width: 100%;
      cursor: pointer;

      input {
        opacity: 0.001;
        position: absolute;
      }
    }
  }
}
