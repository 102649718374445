/*
  _spinner.scss

  spinner style. to use, do following in html
  ```
    <span className="spinner">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </span>
  ```
*/

.spinner {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;

  // there should be exactly 4 empty spans
  & > span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 23px;
    height: 23px;
    margin: 0px;
    border: 4px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation-delay: calc(-1 * (0.45s - (#{$i} - 1) * 0.15s));
      }
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
