/*
  ImageView.module.scss

  This file contains all scoped style for ImageView.jsx.
*/

@import '../../Styles/global';

.imageView {
  margin: 0;
  margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM !important;
  font-size: font-size(-1);
  overflow: visible;
  position: relative;
  width: fit-content;

  &.modal {
    overflow: auto;
    margin: auto;
  }

  .modal-image {
    text-align: center;
  }

  button {
    margin-bottom: $CONTENT_ELEMENT_INNER_MARGIN;
    margin-right: auto;
    margin-left: auto;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .clickableImage {
    padding: 0;
    outline-color: transparent;
    transition: all ease-in 300ms;
    position: relative;

    img {
      display: block;
    }

    .expandIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 45px;
      height: 45px;
      border: 1px solid $PRIMARY;
      background-color: $BACKGROUND;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      opacity: 0;
      transition: inherit;

      svg {
        height: 24px;
        width: 24px;
        color: $BLACK;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: $BLACK;
      position: absolute;
      top: 0;
      left: 0;
      transition: inherit;
    }

    &:focus {
      outline: 6px solid $LINK_HOVER;
      outline-offset: -6px;
    }

    &:hover {
      .expandIcon {
        opacity: 1;
      }

      &:before {
        opacity: 0.4;
      }
    }
  }

  button {
    display: block;
  }

  .open {
    margin-left: $CONTENT_ELEMENT_MARGIN_BOTTOM;
    font-size: font-size(0);
  }

  @include print {
    margin-bottom: $PRINT_ELEMENT_MARGIN !important;

    figcaption {
      text-align: center;
      font-size: font-size(0, $PRINT_TYPE_SCALE);
      color: $GREY_06;
    }
  }
}
