/*
  Tag.module.scss

  This file contains all scoped style for Tag.jsx.
*/

@import '../../Styles/global';

.tag {
  display: flex;
  font-size: font-size(-2);
  font-weight: $MEDIUM;
  color: $BLACK;
  background-color: $BACKGROUND;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  border: 1px solid $GREEN_03;
  word-break: break-word;

  padding: 3px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  &.highlight {
    background-color: $SECONDARY;
    border-color: $YELLOW_03;
  }

  &.grey {
    background-color: $GREY_02;
    border-color: $GREY_04;
  }

  &.dark {
    background-color: $PRIMARY;
    color: $WHITE;
    border-color: $PRIMARY;
  }

  // disabled style is for in case tag is used for filter input in future
  &:disabled,
  &.disabled {
    background-color: $GREY_02 !important;
    border-color: $GREY_04 !important;
    color: $GREY_04 !important;
    cursor: not-allowed;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  button {
    padding: 0;
    margin-left: 10px;
    margin-right: 3px;
    width: font-size(0);
    height: font-size(0);
  }
}
