/*
  DataSetsTable.module.scss

  This file contains all scoped style for DataSetsTable.jsx.
*/

@import '../../Styles/global';

.dataSetTable {
  border-spacing: 0;
  background-color: $WHITE;
  --MAX-LINE: 3;

  &.appendTable {
    tr {
      td {
        text-align: left;
        position: relative;

        &:hover {
          .expandCellButton {
            opacity: 1;
          }
        }

        .expandCellButton {
          position: absolute;
          padding: 5px;
          font-size: font-size(-1);
          bottom: 5px;
          right: 5px;
          opacity: 0.01;

          &:focus {
            opacity: 1;
          }

          &:hover {
            opacity: 1;
          }

          &.expanded {
            position: sticky;
            float: right;
            bottom: 20px;
            right: revert;
          }
        }
      }
    }
  }

  tr {
    th,
    td {
      padding: 8px;
      border: 1px solid $GREY_04;
    }

    th {
      text-align: left;
    }

    td {
      text-align: right;
    }
  }

  .greenBackground {
    background-color: $BACKGROUND;
  }

  .whiteBackground {
    background-color: $WHITE;
  }

  .headingRow {
    border-right: none;
    border-left: none;
    background-color: $GREY_01;

    &:first-child {
      border-left: 1px solid $GREY_04;
    }
    &:last-child {
      border-right: 1px solid $GREY_04;
    }
  }
}

.limitTextLines {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--MAX-LINE);
  overflow: hidden;
}
