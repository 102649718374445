/*
  SearchFilterList.module.scss

  This file contains all scoped style for SearchFilterList.jsx.
*/
@import '../../../Styles/global';

.searchFilterList {
  width: 480px;
  display: flex;
  flex-direction: column;
  max-height: 500px;

  @include mobile {
    width: 100%;
    max-height: calc(100vh - 175px);
  }

  .searchBar {
    @include mobile {
      padding: 0 16px 16px 16px;
      border-bottom: 2px solid $GREEN_02;
    }
  }

  .noResults {
    padding: 20px;
  }
}
