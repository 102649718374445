/*
  Loading.module.scss

  This file contains all scoped style for Loading.jsx.
*/

@import '../../Styles/global';

.loading {
  width: 100%;

  animation: flashing 1.2s infinite;
}

@keyframes flashing {
  0% {
    background: none;
  }
  100% {
    background-color: $GREEN_01;
  }
}
