/*
  Tooltip.module.scss

  This file contains all scoped style for Tooltip.jsx.
*/

@import '../../Styles/global';

.tooltipWrapper {
  position: relative;
  display: inline-block;
  $TOOLTIP_PADDING: 16px;

  &:hover,
  &:focus,
  &:focus-within {
    z-index: $TOOLTIP_Z_INDEX;
  }

  &:hover,
  &:focus,
  &:focus-within {
    .tooltip {
      .tooltipText {
        &.top,
        &.bottom,
        &.left,
        &.right,
        &.topLeft,
        &.topRight,
        &.bottomLeft,
        &.bottomRight {
          @keyframes modal-fade {
            0% {
              opacity: 0.2;
            }
            25% {
              opacity: 0.4;
            }
            50% {
              opacity: 0.6;
            }
            75% {
              opacity: 0.8;
            }
            100% {
              opacity: 1;
            }
          }

          animation: modal-fade 0.5s ease-in;
          opacity: 1;
          z-index: $TOOLTIP_Z_INDEX;
        }
      }
    }
  }

  .tooltip {
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: -1;

    .tooltipText {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 16px solid $GREY_07;
      }

      &.top {
        // transforms the tooltip to the center of element horizontally
        transform: translateX(calc(-50% + var(--child-element-width) / 2));
        bottom: $TOOLTIP_PADDING;

        &:before {
          // this styles the position of triangle value to be on bottom of tooltip
          transform: translate(-50%, calc(var(--tooltip-height) / 2));
        }
      }

      &.bottom {
        // transforms the tooltip to the center of element horizontally
        transform: translateX(calc(-50% + var(--child-element-width) / 2));
        top: calc(var(--child-element-height) + $TOOLTIP_PADDING);

        &:before {
          // this styles the position of triangle value to be on top of tooltip
          transform: translate(-50%, calc(var(--tooltip-height) / 2 * -1 - $TOOLTIP_PADDING)) rotate(180deg);
        }
      }

      &.left {
        // transforms the tooltip to be the center of element vertically
        right: $TOOLTIP_PADDING;
        transform: translateY(calc(-50% + var(--child-element-height) / 2));

        &:before {
          // this styles the position of triangle value to be on the right of element
          transform: translate(calc(var(--tooltip-width) / 2 - $TOOLTIP_PADDING + 8px), -50%) rotate(270deg);
        }
      }

      &.right {
        // transforms the tooltip to be the center of element vertically
        left: calc(var(--child-element-width) + $TOOLTIP_PADDING);
        transform: translateY(calc(-50% + var(--child-element-height) / 2));

        &:before {
          // this styles the position of triangle value to be on the left of element
          transform: translate(calc(var(--tooltip-width) / 2 * -1 - $TOOLTIP_PADDING - 8px), -50%) rotate(90deg);
        }
      }

      &.topLeft {
        transform: translateX(-100%);
        bottom: $TOOLTIP_PADDING;
        border-bottom-right-radius: 0;

        &:before {
          transform: translate(calc(var(--tooltip-width) / 2), calc(var(--tooltip-height) / 2 - 16px)) rotate(270deg);
          border-left: 0px solid transparent;
        }
      }

      &.topRight {
        transform: translateX(var(--child-element-width));
        bottom: $TOOLTIP_PADDING;
        border-bottom-left-radius: 0;

        &:before {
          transform: translate(calc(var(--tooltip-width) / 2 * -1 - 16px), calc(var(--tooltip-height) / 2 - 16px)) rotate(180deg);
          border-left: 0px solid transparent;
        }
      }

      &.bottomLeft {
        transform: translateX(-100%);
        top: calc(var(--child-element-height) + $TOOLTIP_PADDING);
        border-top-right-radius: 0;

        &:before {
          transform: translate(calc(var(--tooltip-width) / 2), calc(var(--tooltip-height) / 2 * -1)) rotate(360deg);
          border-left: 0px solid transparent;
        }
      }

      &.bottomRight {
        transform: translateX(var(--child-element-width));
        top: calc(var(--child-element-height) + $TOOLTIP_PADDING);
        border-top-left-radius: 0;

        &:before {
          transform: translate(calc(var(--tooltip-width) / 2 * -1 - 16px), calc(var(--tooltip-height) / 2 * -1)) rotate(90deg);
          border-left: 0px solid transparent;
        }
      }

      opacity: 0;
      color: $WHITE;
      text-align: center;
      max-width: 256px;
      min-width: 100px;
      width: max-content;
      padding: 8px;
      border-radius: 4px;
      position: absolute;
      background-color: $GREY_07;
      z-index: -1;
    }
  }

  .tooltipText {
    opacity: 0.01;
  }

  .childWrapper {
    font-size: 0; // to remove unwanted height
  }
}
