/*
  AnnualReportContentLoading.module.scss

  This file contains all scoped style for AnnualReportContentLoading.jsx.
*/

@import '../../Styles/global';

.invisibleTitle {
  opacity: 0.001;
}

.articleContent {
  min-height: 200px;
}
