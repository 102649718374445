/*
  HomePageLoading.module.scss

  This file contains all scoped style for HomePageLoading.jsx.
*/

@import '../../Styles/global';
#homepageLoading {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .loadingHero {
    height: 500px;
  }

  .publicationsSection {
    .publicationType {
      margin-top: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
      min-height: fit-content;

      @include mobile {
        display: flex;
        flex-direction: column;
      }

      div {
        min-height: 600px;

        @include mobile {
          min-height: 300px;
        }
      }
    }
  }

  .loadingSection {
    min-height: 440px;
  }

  .relatedResources {
    .relatedLinks {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;

      @include mobile {
        display: flex;
        flex-direction: column;
      }

      div {
        min-height: 166px;
      }
    }
  }
}

.invisibleTitle {
  opacity: 0.001;
  font-size: font-size(5);
}
