/*
  _constants.scss

  This file contains all the constant variables that 
  are used on other style files.

  constat variables must be in capital letters.
*/

/*
===== TABLE OF CONTENT =====
1. COLOURS
2. TIMING VARIABLES
3. TYPOGRAPHY VARIABLES
4. BREAKPOINTS
5. OTHERS
6. Z INDEX
*/

/*==============================================================
1. COLOURS
==============================================================*/

// BASE
$GREEN: #00aa55;
$PINK: #f092e7;
$BLUE: #4c79ed;
$RED: #ff4b4b;
$TURQUOISE: #52c9d0;
$YELLOW: #fadb00;
$PURPLE: #a561da;
$ORANGE: #ff9900;
$PEACH: #ffe6e6;
$MINT: #e9ffe3;
$WHITE: #ffffff;
$BLACK: #000000;

// PRIMARY BRAND
$PRIMARY: #005562;
$BACKGROUND: #e1eceb;
$BACKGROUND_BLUE: #e7f1ff;
$GREEN_01: #eff5f5;
$GREEN_02: #a9c6c9;
$GREEN_03: #679ba2;
$GREEN_04: #387b85;

// SECONDARY BRAND
$SECONDARY: $YELLOW;
$YELLOW_01: #fff6c2;
$YELLOW_02: #ffee8a;
$YELLOW_03: #a89300;

// GREY
$GREY_01: #f9f9f9;
$GREY_02: #efefef;
$GREY_03: #bababa;
$GREY_04: #868686;
$GREY_05: #515151;
$GREY_06: #1c1c1c;
$GREY_07: #343332;

// SYSTEM
$LINK: #0000ee;
$LINK_HOVER: #0068ff;
$LINK_VISITED: #7f1cd9;
$ERROR: #e50000;
$INFORMATION: #b900f5;
$MAUVE: #f7e3ff;
$ACCEPTED: #008040;

// DISABLED
$DISABLED: #484544;

/*==============================================================
2. TIMING VARIABLES
==============================================================*/
$TRANSITION_TIMING: 0.15s;
$TRANSITION_STYLE: cubic-bezier(0, 0.3, 0.82, 1.51);
$CARD_TRANSITION_TIMING: 300ms;

/*==============================================================
3. TYPOGRAPHY VARIABLES
==============================================================*/

// BODY TEXT COLOUR
$BODY_TEXT: $BLACK;
$LINK_TEXT: $LINK;

// BASE FONT SIZE (BASED ON DESKTOP VIEW)
$BASE_TYPE_SIZE: 16px;
$PRINT_TYPE_SIZE: 18.7px; // 14px but because from BE we print at 75% scale, we set print type size close to 18.6666px

// BASE TYPE SCALE (USED IN TYPOGRAPHIC SCALE)
$BASE_TYPE_SCALE: 1.125; // major second
$PRINT_TYPE_SCALE: 1.2;

// BASE LINE HEIGHT
$BASE_LINE_HEIGHT: 1.5;
$PRINT_LINE_HEIGHT: 1.75;

// FONT WEIGT
$REGULAR: 400;
$MEDIUM: 500;
$SEMI_BOLD: 600;
$BOLD: 700;

// DEFAULT HEADING MARGIN TOP AND BOTTOM
$HEADING_MARGIN_TOP: rem(32);
$HEADING_MARGIN_BOTTOM: rem(16);

/*==============================================================
4. BREAKPOINTS
==============================================================*/
$DESKTOP_MIN: 1024px;

$TABLET_MAX: $DESKTOP_MIN - 1px;
$TABLET_MIN: 717px;

$MOBILE_MAX: $TABLET_MIN - 1px;

/*==============================================================
5. OTHERs
==============================================================*/
$SHADOW: 0 -20px 32px 0 tint($BLACK, 64%);
$MAX_CONTENT_WIDTH: 1224px;
$MAX_HEADER_FOOTER_CONTENT_WIDTH: 1440px;
$HEADER_FOOTER_GUTTER: 24px;
$HEADER_FOOTER_GUTTER_MOBILE: 20px;
$MIN_CONTENT_SIDE_PADDING: 20px;
$SIMPLE_BANNER_BRAND_MARK_WIDTH: 288px;
$SIMPLE_BANNER_GAP: 24px;
$CONTENT_ELEMENT_MARGIN_BOTTOM: 16px;
$CONTENT_ELEMENT_INNER_MARGIN: 8px;
$ACCORDION_SIDE_BORDER_WIDTH: 4px;
$BORDER_RADIUS: 4px;
$ARTICLE_CONTENT_MARGIN_BOTTOM_DESKTOP: 128px;
$ARTICLE_CONTENT_MARGIN_BOTTOM_MOBILE: 32px;
$FOCUS_OUTLINE_WIDTH: 6px;
$MAIN_PADDING_BOTTOM: 48px;
$PRINT_ELEMENT_MARGIN: 8px;

/*==============================================================
6. Z INDEX
==============================================================*/
$HEADER_Z_INDEX: 999;
$TOOLTIP_Z_INDEX: $HEADER_Z_INDEX + 1;
$SKIP_BUTTON_Z_INDEX: $HEADER_Z_INDEX + 1;
$MODAL_OVERLAY_Z_INDEX: $SKIP_BUTTON_Z_INDEX + 1;
