/*
  SearchPage.module.scss

  This file contains all style for SearchPage.jsx.
  All scoped style within page not found can be found here.
*/

@import '../../Styles/global';

#bannerContent {
  max-width: 808px;
}



#searchResults {
  padding: 24px 0 48px;

  .dropdownResultOrderWrapper {
    position: relative;
    z-index: 10;
    width: 20%;
    padding-bottom: 2%;
  }

  li {
    width: 100%;
    transition: all ease-out 0.3s, outline 0.0001s;
    position: relative;

    & ~ li {
      margin-top: -1px;
    }

    * {
      transition: inherit;
    }

    &:before,
    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $GREY_04;
      background-color: transparent;
      position: absolute;
      z-index: 2;
      left: 0;
      padding: 0;
    }

    &:before {
      top: 0;
    }

    &:hover,
    &:focus-within {
      z-index: 2;

      &:focus-within {
        z-index: 3;
      }
    }

    &.resultItem {
      --SIDE-PADDING: 16px;
      --TOP-BOTTOM-PADDING: 24px;
      display: grid;
      grid-template-columns: 1fr 416px;
      position: relative;
      min-height: 152px;
      z-index: 1;
      padding: 0;
      font-size: font-size(0);
      font-weight: $REGULAR;
      border-width: 1px;
      border-radius: 4px;
      border-color: $GREY_04 !important;
      margin-bottom: 16px;

      @include mobile {
        margin-bottom: 1px;
        border: none;
        border-bottom: 1px solid;
        border-color: $GREY_04;
        border-radius: 0;
      }

      a {
        text-decoration: none;
        color: $BLACK;

        &:hover,
        &:focus {
          color: $BLACK !important;
          outline-offset: 0px;

          &:before {
            background-color: $PRIMARY;
          }
        }
      }

      .searchItem,
      .entity {
        .hide {
          display: none;
        }

        .display {
          display: block;

          @include mobile {
            display: none;
          }

          &.loadingBackground {
            grid-column: 2;

            @include tablet {
              display: none;
            }

            li {
              background-color: $WHITE;
            }
          }

          li {
            padding: 0;
            &:after {
              content: none;
            }
          }
        }

        .tagHolderAR {
          margin-bottom: 3px;
          height: fit-content;
          display: inline-block;

          @include mobile {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
          }

          @include tablet {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
          }
        }

        .tagHolder {
          margin-bottom: 3px;
          height: fit-content;
          display: inline-block;
        }

        a {
          height: fit-content;
          width: fit-content;
        }

        .entityPortfolioLink {
          text-decoration: underline;
        }

        .entityImage {
          width: 180px;
          height: 120px;
          padding: 8px;
          border: 1px solid $GREY_03;
          grid-column: 2;

          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }

      a,
      .searchItem {
        &:hover,
        &:focus {
          color: $PRIMARY !important;
          outline-offset: 0px;

          &:before {
            background-color: $PRIMARY;
          }
        }
      }

      &:before {
        content: '';
        display: block;
        width: 8px;
        height: 100%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
      }

      .body {
        display: flex;
        flex-direction: column;
        padding: var(--TOP-BOTTOM-PADDING) var(--SIDE-PADDING);
        width: 100%;

        .tagHolder {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
        }

        .annualReportSubTitle {
          font-weight: $SEMI_BOLD;
          font-size: font-size(-1);
          color: $GREY_05;
          line-height: 22px;
        }

        .chapterSubTitle {
          font-weight: $REGULAR;
          color: $GREY_05;
          font-size: 14px;
          line-height: 22px;
        }

        .title {
          margin-top: 16px;
          margin-bottom: 6px;
          font-size: font-size(1);
          font-weight: $SEMI_BOLD;
          text-decoration: underline;

          svg {
            width: 14px;
            height: 14px;
            vertical-align: middle;
            margin-left: 4px;
          }
        }

        .summary {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          color: $BLACK !important;
          word-break: break-all;
        }
      }

      .tail {
        padding: var(--TOP-BOTTOM-PADDING) var(--SIDE-PADDING);
        display: grid;
        grid-template-columns: 1fr 180px;
        gap: 16px;
        background-color: $GREEN_01;
        min-width: 416px;
        height: 100%;
        border-radius: 0 4px 4px 0;

        &.tailOnePart {
          grid-template-columns: 1fr;
        }

        .entityTagsAndLink {
          display: flex;
          flex-direction: column;
        }

        img,
        .defaultCoverImage {
          max-width: 180px;
          grid-column: 2;
          border-radius: 4px;
        }

        .defaultCoverImage {
          background-color: $WHITE;
          border: 1px solid $GREY_03;
          max-height: 118px;
        }

        @include tablet {
          a {
            font-size: font-size(-1);
            text-decoration: underline;
          }
        }

        &.isPublication {
          grid-template-columns: 1fr 80px;

          .imgContainer {
            height: 120px;
            width: 100%;
            grid-column: 2;
            border: 1px solid $GREY_03;
            border-radius: 4px;

            @include tablet {
              display: none;
            }

            img {
              height: 100%;
              width: 100%;
            }
          }
        }

        &.bgImg {
          background-image: var(--BACKGROUND-IMAGE);
          background-repeat: no-repeat;
          background-position-y: 100%;
          background-position-x: calc(100% + 270px);
          background-size: 126%;
        }
      }

      &:hover,
      &:focus,
      &:focus-within {
        color: $BLACK !important;
        outline-offset: 0px;

        &:before {
          background-color: $PRIMARY;
        }

        .tail {
          background-color: $BACKGROUND;
        }
      }

      @include tablet {
        --SIDE-PADDING: 8px;
        --TOP-BOTTOM-PADDING: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-height: 200px;

        &:before {
          width: 4px;
        }

        .body {
          .tagHolder,
          .title {
            gap: 4px;
            margin-bottom: 8px;
          }

          .title {
            font-size: font-size(-1);
          }

          .summary {
            -webkit-line-clamp: 2;
          }
        }

        .tail {
          display: block;
          min-width: 0;

          &:not(.isPublication) {
            display: none;
          }

          img,
          .defaultCoverImage {
            display: none;
          }
        }
      }
    }
  }
}

.hidePagination {
  display: none;
}

.message {
  text-align: center;
  padding-top: calc(72px + 1em);
}
