/*
  _customElements.scss

  This file defines custom element FE styles from Kontent.ai CMS.
  
  Note that every typography global styles are wrapped by #TPApp instead of body or html to
  avoid unwanted style overwrite when it is loaded within CMS (such as preview)
*/

/*
===== TABLE OF CONTENT =====
1. INDENT
2. IMAGE
3. TABLE
4. LINKABLE SECTION
*/

#TPApp {
  /*==============================================================
1. INDENT
==============================================================*/
  .indentElement {
    --INDENT-AMOUNT: 1;
    padding-left: calc(var(--INDENT-AMOUNT) * 40px);
    margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;

    @include tablet {
      padding-left: 24px;
    }
  }

  /*==============================================================
2. IMAGE
==============================================================*/
  .imageElement {
    margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;
    font-size: font-size(-1);

    img,
    button {
      margin-bottom: 8px;
    }

    button {
      display: block;
      color: $BLACK;
    }

    figcaption {
      margin-bottom: 0;
      color: $GREY_05;
    }
  }

  /*==============================================================
3. TABLE
==============================================================*/
  /*
  NOTE: These styles are assuming end user have not specified
  custom style on each row, column and table cells hence considered
  "default" style. If end user have set custom style within Kontent.ai
  cms, as styles would come as inline styling hence any respective
  styles would be ignored
*/
  .tableElement {
    margin: 0;
    margin-bottom: 1rem;
    overflow-x: revert;

    &.clientGenerated {
      overflow-x: auto;
    }

    &.haveCaption {
      margin-top: $HEADING_MARGIN_TOP;
    }

    &:first-child {
      table .tableTitle,
      &.haveCaption {
        margin-top: 0;
      }
    }

    & > .tableOverflowWrapper {
      overflow: auto;
      margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;

      /*
      When content editor copy table from word, it can add unwanted
      margins as inline styling hence making sure table within table 
      element does not have any margin
    */
      & > .advancedFootnotesBody table {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        border-width: 0 !important;
        th,
        td {
          height: 27px; // min default height (when there is no content inside)
        }

        @include mobile {
          min-width: 100% !important;
        }

        &.clientGenerated {
          th,
          td {
            padding: 1rem 0.45em !important;
            word-break: break-word; // ensures that there is no overflow for rows with too much text
          }
        }
      }
    }

    figcaption {
      color: $BLACK;
    }
  }

  // default universal table style
  table {
    margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;

    .tableTitle {
      text-align: left;
      margin-top: 0;
    }

    &.clientGenerated {
      width: 100%;
      border-collapse: collapse;
      border-color: $GREY_03 !important;
      font-size: font-size(-1);

      &#list-of-requirements-table {
        td {
          word-break: break-word;
        }
      }

      &.everySecondbackgroundColor {
        tr {
          &:nth-child(even) {
            background-color: $GREY_01;
          }
        }
      }

      &.webDataTableAppendRow {
        tbody {
          tr {
            &:nth-child(even) {
              background-color: $GREY_02;
            }
          }
        }
      }

      tr,
      th {
        &.backgroundColor {
          background-color: $BACKGROUND;

          &.primary {
            background-color: $PRIMARY;
            color: $WHITE;
          }

          &.section {
            background-color: $GREEN_03;
          }
        }
      }

      th,
      td {
        padding: 8px;
        min-width: min-content;
        text-align: initial;
        vertical-align: top;
        border: 1px solid $GREY_03 !important;

        &.thi & > *:last-child {
          margin-bottom: 0;
        }
      }

      th {
        &.noWrap {
          white-space: nowrap;

          @include mobile {
            white-space: revert;
          }
        }
      }
    }

    &:not(.clientGenerated) {
      thead,
      tbody {
        th,
        td {
          p {
            margin-top: 0; // to render exactly same as one in Kontent.ai as requirement
            margin-bottom: 0; // to render exactly same as one in Kontent.ai as requirement

            &:first-child,
            &:last-child {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  // tables under migrated AR only. always set table max width to 100%
  .migrated-chapter {
    table {
      max-width: 100%;
      border-collapse: collapse;
      border: 0 solid transparent;
      border-spacing: 0;
      hyphens: none;
      background-color: $WHITE;

      td,
      th {
        padding: 6px 10px;
        vertical-align: top;
        border-bottom: 1px solid #bdc4c7;
      }

      tr {
        &:nth-child(even) {
          background-color: #f9fbfc;
        }
      }
    }

    .md-table-wrapper {
      overflow: auto;
      margin-bottom: $CONTENT_ELEMENT_MARGIN_BOTTOM;
    }

    .md-table-align-center {
      text-align: center;
    }

    .md-table-align-right {
      text-align: right;
    }

    .md-table-align-left {
      text-align: left;
    }

    $MIGRATED_REPORT_BORDER_COLOUR: #003450;
    .md-table-border-bottom {
      border-bottom: 2px solid $MIGRATED_REPORT_BORDER_COLOUR;
    }

    .md-table-border-top {
      border-top: 2px solid $MIGRATED_REPORT_BORDER_COLOUR;
    }

    .md-table-border-left {
      border-left: 2px solid $MIGRATED_REPORT_BORDER_COLOUR;
    }

    .md-table-border-right {
      border-right: 2px solid $MIGRATED_REPORT_BORDER_COLOUR;
    }
  }

  /*==============================================================
4. LINKABLE SECTION
==============================================================*/
  .linkableSection {
    position: relative;
  }
}
